import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-faq-add',
  templateUrl: './faq-add.component.html',
  styleUrls: ['./faq-add.component.css'],
})
export class FaqAddComponent implements OnInit {
  addfaqForm!: FormGroup;
  submitted = false;
  token: any;
  constructor(
    public router: Router,
    private faqService: FaqService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.addfaqForm = this.formBuilder.group({
      Question: ['', Validators.required],
      Answer: ['', Validators.required],
    })
  }

  get addfq() {
    return this.addfaqForm.controls;
  }

  onSubmit() {
    console.log("wzedrtfgyhujimko,l")
    this.submitted = true;
    if (this.addfaqForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let obj = {
      question: this.addfaqForm.get('Question')?.value,
      answer: this.addfaqForm.get('Answer')?.value,
    };
    this.faqService.faqAdd(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/faq']);
        this.addfaqForm.reset();
        this.submitted = false;
      } else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

}
