<app-header></app-header>

<div class="middle-content-wrap">
  <div class="globalheading dflex dflex_bet">
    <h2>
      All Connections
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="search-wrap">
          <input class="form-control" placeholder="Search" type="text" id="shares" (keyup)="onKeyUpEvent($event)" />
          <i class="fas fa-search"></i>
        </div>
        <div class="common_btn">
          <button class="btn btn-secondary" (click)="runUserActions()">
            <i class="fa fa-users"></i> Run User Actions
          </button>
          <button class="btn btn-secondary" (click)="runAlgorithm()">
            <i class="fa fa-play"></i> Run Matching Algorithm
          </button>
        </div>
      </div>
    </div>
  </div>
  <ul class="nav nav-tabs mt-3">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="(tab === 'table') ? 'active' : ''" href="javascript:void(0);"
        (click)="setTab('table')">Table View</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="(tab === 'overview') ? 'active' : ''" href="javascript:void(0);"
        (click)="setTab('overview')">Total Overview</a>
    </li>
  </ul>
  <div *ngIf="tab === 'table'">
    <div class="tabledatawrap table-responsive">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Sender ID</th>
            <th>Sender Name</th>
            <th>Receiver ID</th>
            <th>Receiver Name</th>
            <th>Match Date</th>
            <th>Sender Status</th>
            <th>Receiver Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of (filteredConnections | paginate: { id: 'connections', itemsPerPage: pageSize, currentPage: p, totalItems: total })">
            <td>{{item.id}}</td>
            <td>{{item.sender_id}}</td>
            <td>
              <div class="d-flex flex-row align-items-center" style="gap: 12px">
                {{item.sender_name}}
                <button class="btn btn-outline-secondary btn-sm" (click)="showUserOverview(item.sender_id)">
                  <i class="fa fa-eye"></i>
                </button>
              </div>
            </td>
            <td>{{item.receiver_id}}</td>
            <td>
              <div class="d-flex flex-row align-items-center" style="gap: 12px">
                {{item.receiver_name}}
                <button class="btn btn-outline-secondary btn-sm" (click)="showUserOverview(item.receiver_id)">
                  <i class="fa fa-eye"></i>
                </button>
              </div>
            </td>
            <td>{{(item.match_date | date: 'YYYY-MM-dd')}}</td>
            <td>
              <span *ngIf="item.sender_status=='Yes'" class="badge badge-success">Yes</span>
              <span *ngIf="item.sender_status=='Pass'" class="badge badge-danger">Pass</span>
              <span *ngIf="item.sender_status=='Maybe'" class="badge badge-secondary">Maybe</span>
            </td>
            <td>
              <span *ngIf="item.receiver_status=='Yes'" class="badge badge-success">Yes</span>
              <span *ngIf="item.receiver_status=='Pass'" class="badge badge-danger">Pass</span>
              <span *ngIf="item.receiver_status=='Maybe'" class="badge badge-secondary">Maybe</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 custompagination">
        <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="connections"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <div *ngIf="tab === 'overview'">
    <div class="row mt-3">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-0">Available Users</h5>
            <p class="text-secondary">Available users / Total users</p>
            <div class="display-4 mb-0 mt-3">
              {{totalOverview?.availableUsers ?? 0}} / {{totalOverview?.totalUsers ?? 0}}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-0">Missing Users</h5>
            <p class="text-secondary">Users who didn't get any new connections</p>
            <div class="display-4 mb-0 mt-3">
              {{totalOverview?.missingUsers ? totalOverview.missingUsers.length : 0}}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-0">Last Missing Users</h5>
            <p class="text-secondary">Users who's missing from the last matching</p>
            <div class="display-4 mb-0 mt-3">
              {{totalOverview?.missingUsersFromLastMatch ? totalOverview.missingUsersFromLastMatch.length : 0}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title mb-0">New Connections</h6>
            <div class="display-6 mb-0 mt-2">
              {{totalOverview?.newConnections ?? 0}}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title mb-0">Duplicated Connections</h6>
            <div class="display-6 mb-0 mt-2">
              {{totalOverview?.duplicatedConnections ?? 0}}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title mb-0">Mutual Connections</h6>
            <div class="display-6 mb-0 mt-2">
              {{totalOverview?.mutualConnections ?? 0}}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title mb-0">Pending Connections</h6>
            <div class="display-6 mb-0 mt-2">
              {{totalOverview?.mutualConnections ?? 0}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <h5>Duplicated Connections</h5>
      <div class="tabledatawrap table-responsive">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Sender ID</th>
              <th>Sender Name</th>
              <th>Receiver ID</th>
              <th>Receiver Name</th>
              <th>Match Date</th>
              <th>Sender Status</th>
              <th>Receiver Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of (totalOverview?.duplicatedRecords ?? [])">
              <td>{{item.id}}</td>
              <td>{{item.sender_id}}</td>
              <td>
                <div class="d-flex flex-row align-items-center" style="gap: 12px">
                  {{item.sender_name}}
                  <button class="btn btn-outline-secondary btn-sm" (click)="showUserOverview(item.sender_id)">
                    <i class="fa fa-eye"></i>
                  </button>
                </div>
              </td>
              <td>{{item.receiver_id}}</td>
              <td>
                <div class="d-flex flex-row align-items-center" style="gap: 12px">
                  {{item.receiver_name}}
                  <button class="btn btn-outline-secondary btn-sm" (click)="showUserOverview(item.receiver_id)">
                    <i class="fa fa-eye"></i>
                  </button>
                </div>
              </td>
              <td>{{(item.match_date | date: 'YYYY-MM-dd')}}</td>
              <td>
                <span *ngIf="item.sender_status=='Yes'" class="badge badge-success">Yes</span>
                <span *ngIf="item.sender_status=='Pass'" class="badge badge-danger">Pass</span>
                <span *ngIf="item.sender_status=='Maybe'" class="badge badge-secondary">Maybe</span>
              </td>
              <td>
                <span *ngIf="item.receiver_status=='Yes'" class="badge badge-success">Yes</span>
                <span *ngIf="item.receiver_status=='Pass'" class="badge badge-danger">Pass</span>
                <span *ngIf="item.receiver_status=='Maybe'" class="badge badge-secondary">Maybe</span>
              </td>
              <td>
                <div class="d-flex flex-row">
                  <button class="btn btn-outline-secondary btn-sm" (click)="deleteConnection(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr *ngIf="!totalOverview?.duplicatedConnections">
              <td colspan="8" class="text-center">
                <div class="text-center text-secondary">
                  No duplicated connections
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-5">
      <h5>Missing Users</h5>
      <div class="tabledatawrap table-responsive">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of (totalOverview?.missingUsers ?? [])">
              <td>{{item.id}}</td>
              <td>
                <div class="d-flex flex-row align-items-center" style="gap: 12px">
                  {{item.name}}
                </div>
              </td>
              <td>{{item.email}}</td>
            </tr>
            <tr *ngIf="!totalOverview?.missingUsers || totalOverview.missingUsers.length < 1">
              <td colspan="3" class="text-center">
                <div class="text-center text-secondary">
                  No missing users
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-5">
      <h5>Missing Users From Last Matching</h5>
      <div class="tabledatawrap table-responsive">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Current New Connections</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of (totalOverview?.missingUsersFromLastMatch ?? [])">
              <td>{{item.id}}</td>
              <td>
                <div class="d-flex flex-row align-items-center" style="gap: 12px">
                  {{item.name}}
                </div>
              </td>
              <td>{{item.email}}</td>
              <td>{{item.new_connections}}</td>
            </tr>
            <tr *ngIf="!totalOverview?.missingUsersFromLastMatch || totalOverview.missingUsersFromLastMatch.length < 1">
              <td colspan="4" class="text-center">
                <div class="text-center text-secondary">
                  No missing users from the last matching
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="viewUserDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 1236px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewUserDetailsLabel">User Overview</h5>
      </div>
      <div class="modal-body">
        <div class="border bg-white position-relative">
          <div id="connections-chart" style="width: 1200px; height: 700px;">
          </div>
          <div class="position-absolute p-2"
            style="left: 6px; top: 6px; font-size: 12px; background-color: #00000088; color: white; border-radius: 8px;">
            Mutual connections: {{overview.mutualConnections}}<br />
            Sent connections: {{overview.sentConnections}}<br />
            Received connections: {{overview.receivedConnections}}<br />
            Passed connections: {{overview.passedConnections}}<br />
            Maybe connections: {{overview.maybeConnections}}<br />
            New connections: {{overview.newConnections}}<br />
            Duplicated connections: {{overview.duplicates}}
          </div>
        </div>
        <div class="mt-1 d-flex flex-row" style="gap: 12px;">
          <div class="d-flex flex-row align-items-center">
            <div style="width: 14px; height: 14px;" [style.background-color]="linkColors['Yes']"></div>
            <span>&nbsp;Yes</span>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div style="width: 14px; height: 14px;" [style.background-color]="linkColors['Pass']"></div>
            <span>&nbsp;Pass</span>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div style="width: 14px; height: 14px;" [style.background-color]="linkColors['Maybe']"></div>
            <span>&nbsp;Maybe</span>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div style="width: 14px; height: 14px;" [style.background-color]="linkColors['Pending']"></div>
            <span>&nbsp;New</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideUserOverview()">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="runUserActions" tabindex="-1" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="runUserActionsLabel">Run User Actions</h5>
      </div>
      <div class="modal-body">
        <p>
          You are going to run user actions on all the users. Are you sure you want to proceed?
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideUserActions()">Close</button>
        <button type="button" class="btn btn-success" [disabled]="runningUserActions" (click)="startUserActions()">
          {{ runningUserActions ? 'Running' : 'Confirm' }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="runMatchingAlgorithm" tabindex="-1" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="runMatchingAlgorithmLabel">Run Matching Algorithm</h5>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger">
          <p>
            <strong>Warning!</strong> Running the matching algorithm will send new connections to available users with
            notifications.
            Are you sure to proceed?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideAlgorithm()">Close</button>
        <button type="button" class="btn btn-success" [disabled]="runningAlgorithm" (click)="startMatchingAlgorithm()">
          {{ runningAlgorithm ? 'Running' : 'Confirm' }}
        </button>
      </div>
    </div>
  </div>
</div>