import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
import { EventService } from 'src/app/services/event.service';
declare var $: any;
@Component({
  selector: 'app-sub-admin-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.css'],
})
export class eventAddComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  token: any;
  roleList: any;
  addsubadminForm!: FormGroup;
  submitted: any;
  menuArr: any = [];
  menuErr = true;
  menuArr1: any = [];
  blankImg: any = false;
  image:any=''
  
  constructor(
    public router: Router,
    private eventService:EventService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    private prefrencesService:PreferencesService 

  ) {}

  ngOnInit(): void {
    this.addsubadminForm = this.formBuilder.group({
      title: ['', [Validators.required,this.prefrencesService.noSpacesValidator]],
      location: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      dateTime: ['', [Validators.required]],
      url: ['', [Validators.required,Validators.pattern(/^(https?:\/\/)?([\w.]+\.\w{2,})(\/.*)?$/i)]],
      description:['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image: ['',[Validators.required]],
    });
  }

  get addSub() {
    return this.addsubadminForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.addsubadminForm.invalid) {
      this.blankImg == true;
      return;
    }
    if(this.image === ''){
      alert('Please Upload Image')
    }
    this.token = localStorage['token'];
    var formData = new FormData ();
    formData.append('title', this.addsubadminForm.get('title')?.value);
    formData.append('location', this.addsubadminForm.get('location')?.value);
    formData.append('dateTime', this.addsubadminForm.get('dateTime')?.value);
    formData.append('url',this.addsubadminForm.get('url')?.value );
    formData.append('description',this.addsubadminForm.get('description')?.value );
    formData.append('image', this.image );

    this.eventService.eventAdd(formData, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/event-list']);
        this.addsubadminForm.reset();
        this.submitted = false;
      }
      else if(res && res.status == 400){
        this.toastr.success(res.message);
      }
      else if(res && res.status == 409){
        this.toastr.success(res.message);
      }
    });
  }


  formateDate(dateTime:Date){
    const adjustedDate = new Date(dateTime);
    adjustedDate.setHours(adjustedDate.getHours() - 5);
    adjustedDate.setMinutes(adjustedDate.getMinutes() - 30);
    return adjustedDate
   }

   onImageSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.image = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (this.calculateAspectRatio(width, height) !== 5 / 3) {
                alert('Please upload an image with a 5:3 aspect ratio.');
                this.resetFileInput();
              } else {
                // alert('NOT CHECK')
              }
            };
    }
 }
}


resetFileInput() {
  // Reset the file input to clear the selected file
  this.fileInput!.nativeElement.value = '';
}

 calculateAspectRatio(width: number, height: number): number {
  return width / height;
}

}
