import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { StaticContentService } from 'src/app/services/static-content.service';
// import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import * as Editor from 'ckeditor5-custom-build/build/ckeditor';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {
  ckeditor:any = '';
  // public Editor = ClassicEditor;
  token: any;
  content: any;
  privacyCont:any;
  sideStatus: any;
  email = 'maxiondevs@weagile.com'
  public Editor:any = Editor;
  config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'undo',
      'redo',
      '|',
      'numberedList',
      'bulletedList',
      'PageBreak',
      'inserttable',
      'FontColor',
      'FontSize',
      'FontFamily',
      'FontBackgroundColor',
    ],
    fontSize: {
      options: [9, 11, 13, 'default', 17, 19, 21],
    },
    language: 'es'
  };
  
  constructor(
    public router: Router,
    private contentService: StaticContentService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getContent();
  }
  cancelBtn(){
    this.ngOnInit();
  }
  getContent() {
    this.token = localStorage['token'];
    this.contentService.contactusContent(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.content = res.data;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onChange( event: any ) {
    this.privacyCont =  event.editor.getData();
}

  onSubmit() {
    this.token = localStorage['token'];
    let obj = {
      about : this.privacyCont,
      email : this.email
    };
    this.contentService.contactusupdateContent(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/contact-us']);
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  works(event:any){
    event.sideName.menuList.forEach((element:any) => {
      if(element.role_name=='Static Content')
      {
        this.sideStatus=element.role_status;
      }
    });
  }
}
