import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  token: any;
  menuList: any;
  userData: any;
  menuArr: any = [];
  menuStatus: any;
  isShow: any = false
  show: any;

  @Input() menuList1: any;
  constructor(public router: Router) { }

  ngOnInit(): void {
    this.userData = localStorage.getItem('userType');
    this.sidebarListing();

    $(document).on('click', '#mobsidemenu', function () {
      $('#wrapper').toggleClass('hidenav');
      $('.sidenavwrap').slideLeft(300);
    });

    $(document).on('click', '.submenu1', function () {
      $('.secondmenu').removeClass("show");
      $('.addClass').addClass('collapsed')
    })

    $(document).on('click', '.submenu2', function () {
      $('.firstmenu').removeClass("show");
      $('.firstaddClass').addClass('collapsed')
    })

    $(document).on('click', '.sub-menu-wrap.submenu1', function () {
      $('#submenu1sub1').find(".collapse").removeClass("show");
    })

    $(document).on('click', '.nav-nav-link', function () {
      $('.nav-nav-link').parent(".collapse").addClass("show");
    })
  }

  sideBarStaticArray: any =
    [
      {
        "role_id": 1,
        "role_name": "Dashboard",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 2,
        "role_name": "Customers",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 3,
        "role_name": "Master Data",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 17,
        "role_name": "All Connections",
        "status": "Active",
        "role_status": "Edit"
      },

      // {
      //     "role_id": 4,
      //     "role_name": "Subscriptions",
      //     "status": "Active",
      //     "role_status": "Edit"
      // },
      {
        "role_id": 5,
        "role_name": "Payments",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 6,
        "role_name": "Push Notifications",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 7,
        "role_name": "Partnerships",
        "status": "Active",
        "role_status": "Edit"
      },
      // {
      //     "role_id": 7,
      //     "role_name": "Promotional Codes",
      //     "status": "Active",
      //     "role_status": "Edit"
      // },
      {
        "role_id": 9,
        "role_name": "Settings",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 10,
        "role_name": "Event Management",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 12,
        "role_name": "FAQs",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 13,
        "role_name": "Static Content",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 14,
        "role_name": "Promo Code",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 15,
        "role_name": "Manual Matches",
        "status": "Active",
        "role_status": "Edit"
      },
      {
        "role_id": 16,
        "role_name": "Referral Management",
        "status": "Active",
        "role_status": "Edit"
      },
      // {
      //     "role_id": 15,
      //     "role_name": "Chat Management",
      //     "status": "Active",
      //     "role_status": "Edit"
      // },
      // {
      //     "role_id": 16,
      //     "role_name": "Trial Package",
      //     "status": "Active",
      //     "role_status": "Edit"
      // }
    ]

  sidebarListing() {
    this.token = localStorage['token'];
    if (this.sideBarStaticArray) {
      this.menuList = this.sideBarStaticArray
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuStatus = this.menuList[i].role_status;
        this.menuList[i].show = false;
        if (this.menuArr.includes('' + this.menuList[i].role_id + '') == true) {
          this.menuList[i].show = true;
        }
      }
    }
  }

}
