<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <div class="globalheading dflex dflex_bet">
    <h2>
      <span>Settings</span> <span class="navigation-arrow">&gt;</span>
      <span>Change-password</span>
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="common_btn">
          <button class="export-btn" [routerLink]="['/dashboard']">Cancel</button>
          <button (click)="onSubmit()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-admin-add-form">
    <div class="add-data-form" [formGroup]="changePassword">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group full-input-wrap">
            <p>
              <label>Old Password <span>*</span></label>
              <span class="your-name">
                <input name="your-name" value="" size="40" class="form-control" aria-required="true"
                  aria-invalid="false" [type]="showOldPass" autocomplete="off" placeholder="Old Password"
                  formControlName="oldPassword" [ngClass]="{
                      'is-invalid': submitted && addForm['oldPassword'].errors
                    }" />
                <div class="errorDiv" *ngIf="submitted && addForm['oldPassword'].errors" class="invalid-feedback">
                  <div class="error" *ngIf="addForm['oldPassword'].errors['required']">
                    Old Password is required
                  </div>
                </div>
              </span>
            </p>
            <span class="toggle-password" (click)="showOldPassword()">
              <i  [ngClass]="showOldPass=='password' ? 'fa fa-eye-slash' : 'fa fa-eye'" ></i>
            </span>
          </div>
          <div class="form-group full-input-wrap">
            <p>
              <label>New Password <span>*</span></label>
              <span class="your-name">
                <input name="your-name" value="" size="40" class="form-control" aria-required="true"
                  aria-invalid="false" [type]="showPassword" autocomplete="off" placeholder="New Password"
                  formControlName="newPassword" [ngClass]="{
                    'is-invalid': submitted && addForm['newPassword'].errors
                  }" />
                <div class="errorDiv" *ngIf="submitted && addForm['newPassword'].errors" class="invalid-feedback">
                  <div class="error" *ngIf="addForm['newPassword'].errors['required']">
                    New Password is required
                  </div>
                </div>
              </span>
            </p>
            <span class="toggle-password" (click)="showNewPassword()">
              <i [ngClass]="showPassword=='password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->