import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-usually-wake-up-add',
  templateUrl: './usually-wake-up-add.component.html',
  styleUrls: ['./usually-wake-up-add.component.css']
})
export class UsuallyWakeUpAddComponent implements OnInit {
  usuallyWakeUpAddForm!:FormGroup;
  submitted: any;
  token: any;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.usuallyWakeUpAddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['',[Validators.required]]
    });

  }


  get addForm() {return this.usuallyWakeUpAddForm.controls;}

  image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
    this.usuallyWakeUpAddForm.value.image= this.image
}

  onSubmit(){
    this.submitted = true;
    if (this.usuallyWakeUpAddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
   formData.append('name',this.usuallyWakeUpAddForm.get('name')?.value)
   formData.append('image', this.image)
    this.prefrencesService.addIUsuallyWakeAt(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.usuallyWakeUpAddForm.reset();
        this.router.navigate(['/wake-up-list']);
        this.submitted = false;
      }
    });
  }

}
