import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(public http: HttpClient) {}
  baseUrl = environment.baseUrl;
  profileSubject = new Subject()

  login(data: any) {
    return this.http.post(this.baseUrl + 'adminLogin', data);
  }

}
