import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationService } from 'src/app/services/push-notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent {
  token: any;
  notificationList: any;
  search: any = '';
  p: any = 1;
  count: any = 0;
  total: any = 10;

  constructor( public router: Router,
    private pushnotification: PushNotificationService,
    private toastr: ToastrService,
    public formBuilder : FormBuilder,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.pushnotificationListing()
  }

  pushnotificationListing() {
    let offset = this.p - 1;
    if(this.search != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search :  this.search,
    };
    this.token = localStorage['token'];
    this.pushnotification.pushnotificationList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.notificationList = res.data.data;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }


  onTableDataChange(event: any) {
    this.p = event;
    this.pushnotificationListing();
  }

}
