import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';
import { RgtService } from 'src/app/services/rgt.service';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import * as moment from 'moment';
import 'moment-timezone';
declare var $: any;
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent implements OnInit {
  id: any;
  token: any;
  userInfo: any = [];
  userTranscationinfo: any;
  userPreference: any;
  userSubscription: any;
  userImages: any;
  userChart: any;
  userEdu: any;
  userprofession: any;
  subscriptionFeatures: any;
  status: any
  personalDetails: any
  item: any;
  aboutYourself: any;
  profileStatus: any;
  socialDetails: any;
  addnotificationForm!: FormGroup;
  submitted = false;
  registrationTime: any;
  userType = 'Specific';
  uploadImg: any;
  referralCount: any;
  type: any = '';
  dataMeet: any;
  showDiv = false;
  meetList: any = [];
  matchData: any = [];
  marketStatus: any;
  accessVal: any = false;
  tier: any = "";
  dataTiersList: Select2Data = [];

  constructor(
    public router: Router,
    private customerService: CustomerService,
    private rgtService: RgtService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private pushnotification: PushNotificationService,
  ) { }

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.status = this.activateroute.snapshot.paramMap.get('status')
    console.log("this.id this.status", this.id, this.status);

    this.addnotificationForm = this.formBuilder.group({
      Title: ['', Validators.required],
      Body: ['', Validators.required],
    });

    this.userDetail();
    this.meetData();
  }

  get addf() {
    return this.addnotificationForm.controls;
  }

  userDetail() {
    this.token = localStorage['token'];
    if (this.status == 'unapproved') {
      this.customerService.unApprovedCustomerDetail(this.id, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.personalDetails = res.data.personalDetails;
          console.log(this.personalDetails, 'AAAAAAAAAAAAAAAAAAAA')
          this.personalDetails.registrationDate = moment(this.personalDetails.registrationDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss');
          this.profileStatus = res.data.idVerification;
          this.socialDetails = res.data.socialConnect;
          console.log("this.personalDetails ", this.personalDetails.registrationDate)

          // Get Tiers
          const group_id = res.data.personalDetails.group_id;
          if (group_id) {
            this.rgtService.tierList({
              group_id,
              limit: 100,
              offset: 0,
            }, this.token).subscribe((res: any) => {
              if (res && res.status == 200) {
                let tierList = res.data.data.map((tier: any) => {
                  return {
                    value: tier.id,
                    label: tier.name
                  }
                });

                this.dataTiersList = [{
                  "label": "",
                  "options": tierList
                }]
                this.tier = this.personalDetails.tier_id;
              } else if (res.status == 400 && res.message == "Please provide valid token") {
                this.toastr.error(res.message);
                this.router.navigate(['/login']);
              } else {
                this.toastr.success(res.message);
              }
            })
          }
        }
        else {
        }
      });
    }
    if (this.status == 'approved') {
      this.customerService.approvedCustomerDetail(this.id, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.referralCount = res.data.total_referral
          this.aboutYourself = res.data.aboutYourself
          this.userImages = res.data.uploads.best_photos
          this.personalDetails = res.data.personalDetails;
          this.marketStatus = res.data.subscribedEmail
          console.log(this.personalDetails, 'AAAAAAAAAAAAAAAAAAAA')
          this.personalDetails.registrationDate = moment(this.personalDetails.registrationDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss');
          this.profileStatus = res.data.idVerification
          console.log("this.personalDetails ", this.personalDetails.registrationDate)
          //  this.registrationTime =  moment(moment(this.personalDetails?.registrationDate).format('Y-M-D'))
          this.socialDetails = res.data.socialConnect;
          this.profileStatus = res.data.idVerification
          console.log("this.personalDetails ", this.personalDetails)

          // Get Tiers
          const group_id = res.data.personalDetails.group_id;
          if (group_id) {
            this.rgtService.tierList({
              group_id,
              limit: 100,
              offset: 0,
            }, this.token).subscribe((res: any) => {
              if (res && res.status == 200) {
                let tierList = res.data.data.map((tier: any) => {
                  return {
                    value: tier.id,
                    label: tier.name
                  }
                });

                this.dataTiersList = [{
                  "label": "",
                  "options": tierList
                }]
                this.tier = this.personalDetails.tier_id;
              } else if (res.status == 400 && res.message == "Please provide valid token") {
                this.toastr.error(res.message);
                this.router.navigate(['/login']);
              } else {
                this.toastr.success(res.message);
              }
            })
          }
        }
        else {
        }
      });
    }
  }

  openApprovedUnapprovedUserModal() {
    $('#approveUnapproved').show()
  }

  getAccess(event: any) {
    this.accessVal = event.target.checked;
    console.log(this.accessVal, 'Access')
  }

  approveUnapprovedf() {
    let obj = {
      id: this.id,
    }
    this.token = localStorage['token'];
    let x = document.getElementById('close')
    x!.setAttribute('data-dismiss', 'modal')
    this.customerService.approvedUnapproved(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message)
        $('#approveUnapproved').hide()
        this.userDetail()
      }
    })
  }

  matchStatus(event: any) {
    this.type = event.target.value;
    if (this.type == 'mutual') {
      this.showDiv = !this.showDiv;
    }
    else {
      this.showDiv = false
    }
    console.log(this.type, 'TYPE');
    this.meetData();
  }

  meetinfoData(item: any) {
    console.log(item, 'ITEM+++++++++++=');
    this.matchData = item.meet;
    console.log(this.matchData, '@@@@@@@@@@@@@@@@@')
  }
  meetData() {
    let obj = {
      type: this.type
    }
    this.token = localStorage['token'];
    this.customerService.customerMeetList(this.id, obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.dataMeet = res.data;
        console.log(this.dataMeet, 'SDSDSD+++++++')
        this.meetList = res.data.meet
      }
      else {
      }
    });
  }

  imageData(event: any, item: any) {
    this.uploadImg = item;
    $('#uploadImg').show();
    console.log(this.uploadImg, 'this.uploadImg')
  }

  changeStatus(event: any, item: any) {
    let obj = {
      id: item.user_id,
      status: this.userInfo.status == 'Inactive' ? 'Active' : 'Inactive',
    };
    this.customerService
      .customerStatus(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 'Active') {
        } else if (res.status == 'Inactive') {
        }
        this.userDetail();
      });
  }

  updateTier(event: Select2UpdateEvent) {
    this.tier = event.value;
  }

  formatData(data: any[]): string {
    if (!data || data.length === 0) {
      return 'N/A';
    }
    const names = data.map(item => item.name).join(', ');
    return names;
  }

  getImage(data: any[]): string {
    if (data && data.length > 0 && data[0].image) {
      return data[0].image;
    } else {
      // Return a sample image URL
      return 'assets/images/about-icon.svg';
    }
  }

  capitalizeFirstLetterOfEachWord(input: string): string {
    if (!input || input.trim().length === 0) {
      return 'N/A';
    }

    // Replace underscores with spaces
    const replacedSpaces = input.replace(/_/g, ' ');

    // Capitalize the first letter of each word
    const capitalizedWords = replacedSpaces.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return capitalizedWords;
  }

  onSubmit() {
    this.submitted = true;
    if (this.addnotificationForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    // let index = this.id;
    // this.id.splice(index, 1);
    let obj = {
      title: this.addnotificationForm.get('Title')?.value,
      message: this.addnotificationForm.get('Body')?.value,
      user_type: this.userType,
      ids: this.id.split(),
    };
    console.log(obj, 'OOOOOOOOo')
    this.pushnotification
      .pushnotificationAdd(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
          this.addnotificationForm.reset();
          // this.router.navigate(['/customer-list']);
          this.submitted = false;
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  onTierSubmit() {
    this.submitted = true;
    if (!this.tier) {
      return;
    }
    this.token = localStorage['token'];

    let obj = {
      id: this.id,
      tier: this.tier
    };

    this.customerService.associateTier(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.userDetail();
        this.submitted = false;
        $("#editTierModal").modal("hide");
      } else {
        this.toastr.error(res.message);
      }
    });
  }
}
