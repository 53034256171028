import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RoleService } from 'src/app/services/role.service';
import { RgtService } from 'src/app/services/rgt.service';
declare var $: any;
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  submitted: any;
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  err: any;
  limit: any = 10;
  offset: any;
  adminList: any = [];
  regionGroupList: any = [];
  accessLevel: any;
  dataAccessLevelList: Select2Data = [];
  dataRoleList: Select2Data = [
    {
      "label": "",
      "options": [
        {
          "value": "SUPERADMIN",
          "label": "Super Admin",
        },
        {
          "value": "ADMIN",
          "label": "Admin",
        },
        {
          "value": "MANAGER",
          "label": "Manager",
        },
        {
          "value": "MATCHMAKER",
          "label": "Match Maker",
        }
      ],
    },
  ]
  id: any = "";
  name: any = "";
  email: any = "";
  role: any = "";
  action: any = "";

  constructor(
    public router: Router,
    private rgtService: RgtService,
    private roleService: RoleService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.adminListing();
    this.regionGroupListing();
  }

  adminListing() {
    this.offset = this.p - 1;
    let obj = {
      limit: this.total,
      offset: this.offset,
    };

    this.token = localStorage['token'];

    this.roleService.adminList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.adminList = res.data.data;
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  regionGroupListing() {
    this.token = localStorage['token'];

    this.rgtService.regionGroupList(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.regionGroupList = res.data.data;
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  initModal() {
    this.submitted = false;
    this.id = "";
    this.name = "";
    this.email = "";
    this.role = null;
    this.accessLevel = null;
  }

  openModal(id?: any) {
    this.initModal();

    if (id) {
      this.id = id;
    }
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.adminListing();
  }

  updateAccessLevel(event: Select2UpdateEvent) {
    this.accessLevel = event.value;
  }

  updateRole(event: Select2UpdateEvent) {
    this.role = event.value;
    this.accessLevel = null;

    switch (this.role) {
      case "SUPERADMIN":
        this.dataAccessLevelList = [{
          "label": "",
          "options": [{
            value: "GLOBAL",
            label: "Global"
          }]
        }]
        break;
      case "ADMIN":
        let regionList: any[] = [];
        this.regionGroupList.forEach((region: any) => {
          regionList.push({
            value: region.id,
            label: region.name
          })
        });

        this.dataAccessLevelList = [{
          "label": "",
          "options": regionList
        }]
        break;
      case "MANAGER":
        this.dataAccessLevelList = [];

        this.regionGroupList.forEach((region: any) => {
          let groupList: any[] = [];
          region.groups.forEach((group: any) => {
            groupList.push({
              value: group.id,
              label: group.name
            })
          })

          this.dataAccessLevelList.push({
            "label": region.name,
            "options": groupList
          });
        });
        break;
      case "MATCHMAKER":
        this.dataAccessLevelList = [];

        this.regionGroupList.forEach((region: any) => {
          let groupList: any[] = [];
          region.groups.forEach((group: any) => {
            groupList.push({
              value: group.id,
              label: group.name
            })
          })

          this.dataAccessLevelList.push({
            "label": region.name,
            "options": groupList
          });
        });
        break;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.name == "" || this.email == "" || this.role == "" || this.accessLevel == "") {
      return;
    }

    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name', this.name)
    formData.append('email', this.email)
    formData.append('role', this.role)
    formData.append('accessLevel', this.accessLevel)

    this.roleService.createAdmin(formData, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message)
        this.adminListing();
        $("#addAccessModal").modal("hide");
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  onEmailSubmit() {
    this.token = localStorage['token'];
    let obj = {
      id: this.id,
    };

    this.roleService.sendEmail(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        $("#sendEmailModal").modal("hide");
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  onBlockUnblockDeleteSubmit() {
    this.token = localStorage['token'];
    let obj = {
      id: this.id,
      action: this.action,
    };

    this.roleService.blockUnblockDelete(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        this.adminListing();
        $("#blockUnblockDeleteModal").modal("hide");
      } else {
        this.toastr.error(res.message);
      }
    });
  }
}
