import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.css'],
})
export class FaqEditComponent implements OnInit {
  editfaqForm!: FormGroup;
  submitted = false;
  token: any;
  id: any;
  faqData: any;
  faq_id: any;
  constructor(
    public router: Router,
    private faqService: FaqService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activateroute: ActivatedRoute
  ) {
    this.faq_id = this.activateroute.snapshot.paramMap.get('faq_id');
  }

  ngOnInit(): void {
    this.editfaqForm = this.formBuilder.group({
      Question: ['', Validators.required],
      Answer: ['', Validators.required],
    });
    this.getfaqData();
  }

  get editfq() {
    return this.editfaqForm.controls;
  }

  getfaqData() {
    let obj = {
      faq_id: this.faq_id,
    };
    this.token = localStorage['token'];
    this.faqService.faqDetails(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.faqData = res.data;
        this.editfaqForm.patchValue({
          Question: this.faqData.question,
          Answer: this.faqData.answer,
        });
        // this.toastr.success(res.message);
      } else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.editfaqForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let obj = {
      faq_id: this.faq_id,
      question: this.editfaqForm.get('Question')?.value,
      answer: this.editfaqForm.get('Answer')?.value,
    };
    this.faqService.faqUpdate(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/faq']);
        this.editfaqForm.reset();
        this.submitted = false;
      } else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }
}
