import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MatchesService {
  baseUrl = environment.baseUrl;

  constructor(public http: HttpClient) {}

  getAllConnections(token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.get(this.baseUrl + `/getMatches`, httpOptions);
  }

  runRandomUserActions(token: any) {
    return this.http.get(this.baseUrl + `/runRandomUserActions`, {
      headers: { accessToken: token },
      responseType: 'blob',
    });
  }

  runMatchingAlgorithm(token: any) {
    return this.http.get(this.baseUrl + `/runMatchingAlgorithm`, {
      headers: { accessToken: token },
      responseType: 'blob',
    });
  }

  getConnectionsOverview(token: any) {
    return this.http.get(this.baseUrl + `/getConnectionsOverview`, {
      headers: { accessToken: token },
    });
  }

  deleteConnection(token: any, id: any) {
    return this.http.post(
      this.baseUrl + `/removeConnection`,
      {
        id: id,
      },
      {
        headers: { accessToken: token },
      }
    );
  }
}
