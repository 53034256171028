<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- FAQ HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            <span routerLink="/promo-code" class="breadcumbs">Promo Code</span> <span
                class="navigation-arrow">></span>
            <span>Send Promo Code</span>
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="common_btn">
                    <a href="javascript:void(0);" routerLink="/promo-code"><button
                            class="export-btn">Cancel</button></a>
                    <a href="javascript:void(0);">
                        <button (click)="onSubmit()">Send</button></a>
                </div>
            </div>
        </div>
    </div>
    <div class="add-data-form faq-wrap" >
        <div class="row">
            <div class="col-md-6" [formGroup]="addCodeForm">
                <div class="form-group full-input-wrap">
                    <p>
                        <label>Promo Code<span>*</span></label>
                        <span class="your-name"><input name="your-name" placeholder="Promo Code" value="" size="40"
                                class="form-control" type="text" autocomplete="off" autocomplete="off"
                                formControlName="codeName" [ngClass]="{
                  'is-invalid': submitted && addf['codeName'].errors
                }" />
                            <div class="errorDiv" *ngIf="submitted && addf['codeName'].errors" class="invalid-feedback">
                                <div class="error" *ngIf="addf['codeName'].errors['required']">
                                    Code Name is required
                                </div>
                            </div>
                        </span>
                    </p>
                </div>
                <div class="form-group full-input-wrap">
                    <p>
                        <label>Plan <span>*</span></label>
                        <span>
                            <select class="form-control" (change)="getVal($event)" formControlName="planExpiry"
                                [ngClass]="{
                'is-invalid': submitted && addf['planExpiry'].errors
              }">
                                <option value="">Select Plan</option>
                                <option value="3-month membership">3 Months</option>
                                <option value="12-month membership">12 Months</option>
                            </select>
                            <div class="errorDiv" *ngIf="submitted && addf['planExpiry'].errors"
                                class="invalid-feedback">
                                <div class="error" *ngIf="addf['planExpiry'].errors['required']">
                                    Plan Expiry is required
                                </div>
                            </div>
                        </span>
                    </p>
                </div>
                <!-- <div class="form-group full-input-wrap">
                    <p>
                        <label>Start Date <span>*</span></label>
                        <span>
                            <input class="form-control" type="date" formControlName="startDate" [ngClass]="{
                'is-invalid': submitted && addf['startDate'].errors
              }">
                            <div class="errorDiv" *ngIf="submitted && addf['startDate'].errors"
                                class="invalid-feedback">
                                <div class="error" *ngIf="addf['startDate'].errors['required']">
                                    Start Date is required
                                </div>
                            </div>
                        </span>
                    </p>
                </div> -->

                <div class="form-group full-input-wrap">
                    <p>
                        <label>End Date <span>*</span></label>
                        <span>
                            <input class="form-control" [min]="todayDate" type="date" formControlName="endDate" [ngClass]="{
                 'is-invalid': submitted && addf['endDate'].errors
               }">
                            <div class="errorDiv" *ngIf="submitted && addf['endDate'].errors" class="invalid-feedback">
                                <div class="error" *ngIf="addf['endDate'].errors['required']">
                                    End Date is required
                                </div>
                            </div>
                        </span>
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="listingformat">
                    <div class="search-wrap">
                        <input class="form-control" placeholder="Search Customer" type="text" autocomplete="off"
                            id="shares" (keyup)="onKeyUpEvent($event)" />
                        <i class="fas fa-search"></i>
                    </div>
                    <div class="tabledatawrap table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                                          (change)="checkUncheckAll($event)" />
                                      </th>
                                    <th>User ID</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                let item of userDataa
                  | paginate
                    : {
                        id: 'delivered',
                        itemsPerPage: total,
                        currentPage: p,
                        totalItems: count
                      };
                let i = index
              ">
                                    <!-- [checked]=[userId.includes(item.user_id)] -->
                                    <!-- <td>
                                        <div class="custom-checkbox">
                                            <input type="checkbox" [checked]="checkFun(item.id)" id="{{ item.id}}"
                                                (change)="checkItem($event,item)">
                                            <label for="{{ item.id}}">
                                            </label>
                                        </div>
                                    </td> -->
                                    <td>
                                        <div class="custom-checkbox">
                                          <input type="checkbox" id="{{ item.id }}" [checked]="masterSelected || checkFun(item.id)"
                                            (change)="checkMenu($event, item.id)" />
                                          <label for="{{ item.id }}"></label>
                                        </div>
                                      </td>
                                    <td>#{{ item.id || "NA" }}</td>
                                    <td>{{ item.name || "NA" }}</td>
                                    <td>{{ item.email || "NA" }}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="userDataa?.length == 0">
                                <tr>
                                    <td colspan="12">No Data Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custompagination">
                            <pagination-controls previousLabel="Prev" nextLabel="Next" id="delivered"
                                (pageChange)="onTableDataChange($event)">
                            </pagination-controls>
                        </div>
                        <!-- <div class="col-md-12 custompagination">
                  <pagination-controls class="" id="delivered" (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
              </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Middle Content CSS End Here  -->