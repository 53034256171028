import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $ : any;
@Component({
  selector: 'app-maxion-goals-list',
  templateUrl: './maxion-goals-list.component.html',
  styleUrls: ['./maxion-goals-list.component.css']
})
export class MaxionGoalsListComponent implements OnInit {
  token: any;
  maxionList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  id: any;
  searchText: any = '';
  sideStatus: any;
  isChecked: any;
  educationId: any = [];
  masterSelected: boolean = false;
  exportList: any;
  constructor( public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.educationListing();
  }

  educationListing() {
    let offset = this.p - 1;
    if(this.searchText != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.getMaxionGoals(obj,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.maxionList =  res.data.data;
        this.count = res.data.total_count;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        // this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else{
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.educationListing()
   }

   stopSearchLoader() {
    $('.lds-ring').css('display', 'none');
  }

  searchTextFilter() {
    this.educationListing()
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.educationId = []
    this.educationListing();
  }

  openModal(item: any){
    this.id = item.id;
  }

  deletePop(){
    let obj = {
      id: this.id,
    };
    console.log(obj)
    this.prefrencesService
      .deleteMaxionGoals(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          $('#deletModal').modal('hide');
          this.toastr.success(res.message);
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.educationListing();
      });
  }

  works(event:any){
    event.sideName.menuList.forEach((element:any) => {
      if(element.role_name=='Master Data')
      {
        this.sideStatus=element.role_status;
      }
    });
  }

}
