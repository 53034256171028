import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
  selector: 'app-promo-code-add',
  templateUrl: './promo-code-add.component.html',
  styleUrls: ['./promo-code-add.component.css']
})
export class PromoCodeAddComponent {
  addCodeForm:any=FormGroup;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  search: any = '';
  token:any;
  userDataa:any;
  submitted = false;
  userId: any = [];
  isChecked: any;
  codeMember:any;
  todayDate:any;
  masterSelected: boolean = false;
  userList:any=[];
constructor(
public router : Router,
public promoService : PromoCodeService,
public formBuilder : FormBuilder,
private toastr: ToastrService,
){}

ngOnInit(): void {
  this.addCodeForm = this.formBuilder.group({
    codeName: ['', Validators.required],
    planExpiry: ['', Validators.required],
    // startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  });
  this.allUserListing();

  var now = new Date();
  var toDate: any = now.getDate();
  if (toDate < 10) {
    toDate = '0' + toDate;
  }
  var month: any = now.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var year: any = now.getFullYear();
  let today = year + '-' + month + '-' + toDate;
  this.todayDate = today;

}



checkUncheckAll(event:any) {
  let checkAll = event.target.checked;
  this.masterSelected = checkAll;
  if(this.masterSelected){
    this.userDataa.forEach((element:any) => {
      console.log(element , 'AAAAAAAAAAAAA')
      this.userId.push(element.id)
    });
  }
  else{
    this.userId = [];
  }
}


// checkItem(event: any, item: any) {
//   this.isChecked = event.target.checked;
//   if (this.isChecked) {
//     if (this.userId.indexOf(item.id) == -1) {
//       this.userId.push(item.id);
//     }
//   } else {
//     let index = this.userId.indexOf(item.id);
//     this.userId.splice(index, 1);
//   }
// }

checkMenu(event: any, id: any) {
  this.isChecked = event.target.checked;
  if (this.isChecked) {
    if (this.userId.indexOf(id) == -1) {
      this.userId.push(id);
    }
  } else {
    let index = this.userId.indexOf(id);
    this.userId.splice(index, 1);
  }
}

// checkFun(Id:any){
//  return this.userId.includes(Id)
// }

get addf(){
  return this.addCodeForm.controls;
}


onKeyUpEvent(event: any) {
  this.search = event.target.value;
  this.allUserListing();
}

allUserListing(){
  let offset = this.p - 1;
  if (this.search != '') {
    offset = 0;
  }
  let obj = {
    limit: 10,
    offset: offset,
    search: this.search
  };
  this.token = localStorage['token'];
  this.promoService.userList(obj, this.token).subscribe((res: any) => {
    if (res && res.status == 200) {
      this.count = res.data.total_count;
      this.userDataa = res.data.userData;
    }
  })
}

checkFun(Id:any){
  return this.userId.includes(Id)
 }

 checkItem(event: any, item: any) {
  this.isChecked = event.target.checked;
  if (this.isChecked) {
    if (this.userId.indexOf(item.id) == -1) {
      this.userId.push(item.id);
    }
  } else {
    let index = this.userId.indexOf(item.id);
    this.userId.splice(index, 1);
  }
}

onTableDataChange(event: any) {
  this.p = event;
  this.allUserListing();
}

getVal(event:any){
this.codeMember = event.target.value
}

onSubmit() {
  this.submitted = true;
  if (this.addCodeForm.invalid) {
    return;
  }
  this.token = localStorage['token'];
  let obj = {
    promotional_code: this.addCodeForm.get('codeName')?.value,
    plan_name: this.addCodeForm.get('planExpiry')?.value,
    expiry_date: this.addCodeForm.get('endDate')?.value,
    userIds : this.userId,
    user_type : 'Specific'
  };
  console.log(obj , 'OOOOOOOOo')
  this.promoService
    .promoCodeAdd(obj, this.token)
    .subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.addCodeForm.reset();
        this.router.navigate(['/promo-code']);
        this.submitted = false;
      } else {
        this.toastr.error(res.message);
      }
    });
}


}
