<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>Life Goals</h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="search-wrap">
                    <input class="form-control" placeholder="Search" type="text" [(ngModel)]="searchText"
                        (keyup)="checkSearchString()" (search)="searchTextFilter()">
                    <i class="fas fa-search"></i>
                </div>
                <div class="common_btn">
                    <a href="javascript:void(0);" [routerLink]="['/life-goals-add']" routerLinkActive="active"><button
                            class="add-user-btn" ><img
                                src="assets/images/plus.svg" alt=""> Add New</button></a>
                </div>
            </div>

        </div>
    </div>
    <div class="listingformat">

        <div class="tabledatawrap preferences-table">
            <table>
                <thead>
                    <tr>
                        <!-- <th>S.No</th> -->
                        <th>Name</th>
                        <th>Image</th>
                        <th colspan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of lifeGoalsList | paginate: {id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count};let i = index;">
                        <!-- <td>{{total *(p-1)+i+1}}</td> -->
                        <td>{{item?.name}}</td>
                        <td><img class='table-image-width' src="{{item.image || '/assets/images/Ellipse 24@2x'}}"></td>
                        <td>
                            <div class="action-btns">
                                <button class="anchortag" [routerLink]="['/life-goals-edit', item.id]"
                                    >Edit</button>
                            </div>
                        </td>
                        <td>
                            <div class="action-btns">
                                <button class="anchortag" data-toggle="modal" data-target="#deletModal"
                                    (click)="openModal(item)" >Delete</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="lifeGoalsList?.length == 0">
                    <tr>
                        <td colspan="12">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 custompagination">
                <pagination-controls class="" id="delivered" (pageChange)="onTableDataChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!-- Delete Popup -->
<div class="modal fade bd-example-modal-sm common_modal" id="deletModal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog animated fadeInDown">
        <div class="modal-content">
            <div class="modal-header">
                <div class="popupheding">
                    Delete
                </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="filtercard">
                    <p> Are you sure you want to delete this?</p>
                    <div class="common_btn">
                        <button data-dismiss="modal">Cancel</button>
                        <button (click)="deletePop()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Popup -->