import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private token: any;

  constructor(private router: Router) { }

  isAuthorized() {
    this.token = localStorage.getItem('token');
    return !!this.token;

  }

}
