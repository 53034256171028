import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(public http : HttpClient) { }
  baseUrl = environment.baseUrl

  eventList(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/getEventlist?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  adminDetail(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/getEventdetails?event_id=${obj.id}`, httpOptions);
  }

  eventUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editEvent`,obj, httpOptions);
  }

  subAdminStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `/subadminStatus`,obj, httpOptions);
  }

  deleteEvent(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteEvent`,obj, httpOptions);
  }

  eventAdd(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `createEvent`,obj, httpOptions);
  }

}
