<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- Dashboard HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      Region > {{ regionDetails?.name }}
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="common_btn">
          <button class="export-btn" [routerLink]="['/regions-list']">
            <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
          </button>
        </div>
        <div class="common_btn">
          <button class="add-user-btn" data-toggle="modal" data-target="#upsertGroupModal" (click)="openModal()">
            <img src="assets/images/plus.svg" alt=""> Add Group
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
  <div class="listingformat">
    <div class="tabledatawrap table-responsive">
      <table>
        <thead>
          <tr class="trGroup">
            <th></th>
            <th>Group Name</th>
            <th>No Tiers</th>
            <th>No Members</th>
            <th>Group Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody
          *ngFor="let item of groupList | paginate: {id: 'groups', itemsPerPage: total, currentPage: p , totalItems: count}; let i = index">
          <tr>
            <td class="width15">
              <div class="accordion-toggle" data-toggle="collapse" [attr.data-target]="'#table' + i">
                <i class="fa fa-plus"></i>
              </div>
            </td>
            <td class="width15">{{ item.name || "NA" }}</td>
            <td class="width15">{{ item.count_tier || "NA" }}</td>
            <td class="width15">{{ item.count_customer || "NA" }}</td>
            <td><span [class]="item?.status.toLowerCase()">{{ item.status || "NA" }}</span></td>
            <td class="width20">
              <div class="action-btns hide-profile-btn">
                <button (click)="openModal(item.id, true)" data-toggle="modal" data-target="#upsertGroupModal"
                  data-placement="top" title="Edit">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="hiddenRow">
              <div class="accordian-body collapse" [attr.id]="'table' + i">
                <table class="table table-striped">
                  <thead>
                    <tr class="trTier">
                      <th></th>
                      <th>Tier Name</th>
                      <th>No Members</th>
                      <th>Tier Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tier of item?.tiers">
                      <td class="width15"></td>
                      <td class="width15">{{ tier.name || "NA" }}</td>
                      <td class="width15">{{ tier.count_customer || "NA" }}</td>
                      <td><span [class]="tier?.status?.toLowerCase()">{{ tier.status || "NA" }}</span></td>
                      <td class="width20">
                        <div class="action-btns hide-profile-btn">
                          <button (click)="openTierModal(item.id, tier.id, true)" data-toggle="modal"
                            data-target="#upsertTierModal" data-placement="top" title="Edit">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="width15"></td>
                      <td class="width15">
                        <div class="action-btns">
                          <button class="anchortag" data-toggle="modal" data-target="#upsertTierModal"
                            (click)="openTierModal(item.id)">+ Add
                            Tier</button>
                        </div>
                      </td>
                      <td class="width15"></td>
                      <td></td>
                      <td class="width20"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="groupList?.length==0">
          <tr>
            <td colspan="5">No Data Found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 custompagination">
        <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="groups"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!-- Add Group Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="upsertGroupModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          {{ editing ? "Edit" : "Add"}} Group
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <form>
            <div class="faq-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Group Name <span>*</span></label>
                      <span>
                        <input name="name" [(ngModel)]="groupName" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Name">
                        <div class="errorDiv" *ngIf="submitted && !groupName" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>City <span>*</span></label>
                      <span>
                        <select2 [data]="dataCityList" [value]="groupCity" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateGroupCity($event)">
                        </select2>
                        <div class="errorDiv" *ngIf="submitted && !groupCity" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Status</label>
                      <span>
                        <select2 [data]="dataStatusList" [value]="groupStatus" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateGroupStatus($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="common_btn">
              <button data-dismiss="modal">Back</button>
              <button id="close" (click)="onSubmit()">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add Tier Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="upsertTierModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          {{ editing ? "Edit" : "Add"}} Tier
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <form>
            <div class="faq-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Tier Name <span>*</span></label>
                      <span>
                        <input name="name" [(ngModel)]="tierName" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Name">
                        <div class="errorDiv" *ngIf="submitted && !tierName" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Restrictions</label>
                      <span>
                        <select2 [data]="dataTierRestrictionsList" [value]="tierRestrictions" minCountForSearch="0"
                          multiple="true" noResultMessage="No result" (update)="updateTierRestrictions($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Status</label>
                      <span>
                        <select2 [data]="dataStatusList" [value]="tierStatus" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateTierStatus($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="common_btn">
              <button data-dismiss="modal">Back</button>
              <button id="close" (click)="onTierSubmit()">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>