<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            Promo Code
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="search-wrap">
                    <input class="form-control" placeholder="Search" type="text" id="shares"  (keyup)="onKeyUpEvent($event)" />
                    <i class="fas fa-search"></i>
                </div>
                <div class="common_btn">
                    <a href="javascript:void(0);" routerLink="/promo-code-add"><button class="add-user-btn" ><img src="assets/images/plus.svg" alt=""> Add
                        Code</button></a>
                </div>
            </div>
        </div>
    </div>
    <div class="listingformat">
        <div class="tabledatawrap table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Code </th>
                        <th>Create Date </th>
                        <th>Expiry Date </th>
                        <th>Plan Name</th>
                        <th>Users</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of promoData | paginate: {id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count};">
                        <td>{{item?.id}}</td>
                        <td>{{item?.promotional_code}}</td>
                        <td>{{item?.created_on | date : 'short'}}</td>
                        <td>{{item?.expiry_date | date : 'short'}}</td>
                        <td>{{item?.plan_name}}</td>
                        <td>{{item?.total_users}}</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr *ngIf="promoData?.length == 0">
                        <td colspan="12">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 custompagination">
                <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="delivered"
                    (pageChange)="onTableDataChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->