import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-smoker-edit',
  templateUrl: './smoker-edit.component.html',
  styleUrls: ['./smoker-edit.component.css']
})
export class SmookerEditComponent implements OnInit {
  smokerEditForm!:FormGroup;
  submitted: any;
  token: any;
  id:any;
  smokerData: any;
  image: any;
  imageSrc: any;
  fileName!: string;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.smokerEditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
    this.getEditData()
  }


  get editForm() {return this.smokerEditForm.controls}

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.image = file;
      this.fileName =  file.name
      this.createImagePreview(file);
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  getEditData(){
    let obj = {
      id : this.id
    };
    this.token = localStorage['token'];
    this.prefrencesService.smokerDetails(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.smokerData = res.data;
        this.smokerEditForm.patchValue({
          name:this.smokerData.name,
        });
        const imageName=this.smokerData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
        this.image = this.smokerData.image;
        this.smokerEditForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;  // Assuming image is a URL or base64 data
        }
        // this.toastr.success(res.message);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }


  onSubmit(){
    this.submitted = true;
    if (this.smokerEditForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.smokerEditForm.get('name')?.value)
    formData.append('image',this.image)
    formData.append('id',this.id)
    this.prefrencesService.smokerUpdate(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/smoker']);
        this.smokerEditForm.reset();
        this.submitted = false;
      }
    });
  }
}
