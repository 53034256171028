import { Component, OnInit, ViewChild , Input } from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';
declare var $ : any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!:FormGroup;
  user: any;
  token: any;
  admin_id:any;
  err: any;
  message:'' | undefined;
  notexist :any = '';
  submitted: any;
  show:any;

  constructor(public router: Router, private formBuilder : FormBuilder,
    private toastr:ToastrService, private loginservice: LoginService) { }

  ngOnInit(): void {
    $('.toggle-password').on('click', function() {
      $('.toggle-password i').toggleClass("fa-eye fa-eye-slash");
			if ($('.toggle-password').parents('.passwordfield').find("input.passwordinput").attr("type") === "password") {
				$('.toggle-password').parents('.passwordfield').find("input.passwordinput").attr("type", "text");
			} else {
				$('.toggle-password').parents('.passwordfield').find("input.passwordinput").attr("type", "password");
			}
		});

    this.loginForm = this.formBuilder.group({
      Email: ['',[Validators.required,Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      Password : ['', [Validators.required,Validators.minLength(6),Validators.maxLength(50)]]
    });

  }

  get loginf() { return this.loginForm.controls; }


  onSubmit(){
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let obj = {
      email:this.loginForm.get('Email')?.value,
      password:this.loginForm.get('Password')?.value,
    }
    this.loginservice.login(obj).subscribe((res: any) => {
      if (res.status == 200) {
        this.user = res.data;
        localStorage.setItem('id', JSON.stringify(res.data.id));
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('fullName', res.data.fullName);
        this.loginservice.profileSubject.next(res.data.fullName)
        this.toastr.success(res.message);
        this.router.navigate(['/dashboard'])
        console.log(this.user);
      }
      else if (res.status == 400) {
        if(res.message == 'Invalid Password'){
          this.toastr.error(res.message);
        }
        else{
          this.toastr.error(res.message);
          this.notexist = res.message;
          this.submitted = true;
          this.err = res.data
        }
      }
      else if(res.status == 500){
        this.toastr.error(res.message);
      }
    },
    (err)=>{
      this.toastr.error(err.message);
    });

  }

}
