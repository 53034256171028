import { Component , OnChanges, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  showSidebar:any = false;
  showSidebardiv:any = false;
  token: any;
  menuList: any=[];
  menuStatus: any;
  menuArr: any;
  toastr: any;
  eve: any;
  title = 'af-notification';
  message:any = null;

  show:any;
  constructor(
    private router: Router,
    private activate: ActivatedRoute,
    private titleService:Title,
  ) {

    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        window.scroll(0, 0);

        // Side bar show and hide
          const activeRouteData: any = this.activate.firstChild?.snapshot;
          this.showSidebar = activeRouteData.data.showSidebar !== false;
      }
    });

  }

  path: string[] = [];
  pathTitle: string = '';

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:any) => {
        this.path = event.url.substring(1).split('/');  //  URL
        this.pathTitle = this.activate.firstChild?.snapshot.data['title']; // data.title
    });

    // setTimeout(() => {
    //   this.requestPermission();
    // },3000)
    // this.listen();
  }
  
  menuCheck(event:any){
    console.log("STEP 1")
    this.eve=event;
  }


}




