import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { ToastrService } from 'ngx-toastr';
import { MatchesService } from 'src/app/services/matches.service';
import { StorageService } from 'src/app/services/storage.service';

declare var $: any;

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.css'],
})
export class ConnectionsComponent implements OnInit {
  isUserStatus: string = this.storageService.getItem('isUserStatus')
    ? this.storageService.getItem('isUserStatus')
    : 'approved';

  search: string = '';
  tab: string = 'table';

  connections: any[] = [];
  filteredConnections: any[] = [];
  pageSize: number = 25;
  p: number = 1;
  total: number = 0;

  private svg: any;
  overview = {
    name: '',
    mutualConnections: 0,
    passedConnections: 0,
    maybeConnections: 0,
    sentConnections: 0,
    receivedConnections: 0,
    pendingConnections: 0,
    newConnections: 0,
    duplicates: 0,
  };

  runningUserActions: boolean = false;
  runningAlgorithm: boolean = false;

  linkColors: { [key: string]: string } = {
    Yes: '#198754',
    Pass: '#dc3545',
    Maybe: '#4c00b0',
    Pending: '#666666',
  };

  totalOverview: any = null;

  constructor(
    public router: Router,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private matchesService: MatchesService
  ) {
    this.router.getCurrentNavigation()?.extras;
  }

  ngOnInit(): void {
    this.getMatches();
    this.getConnectionsOverview();
  }

  onKeyUpEvent(event: any) {
    this.search = event.target.value;
    this.filterConnections();
  }

  filterConnections() {
    this.filteredConnections = this.connections.filter(
      (item) =>
        item.sender_name.toLowerCase().includes(this.search.toLowerCase()) ||
        item.receiver_name.toLowerCase().includes(this.search.toLowerCase()) ||
        item.match_date.toLowerCase().includes(this.search.toLowerCase())
    );
    this.total = this.filteredConnections.length;
  }

  setTab(tab: string) {
    this.tab = tab;
  }

  onExport() {}

  getMatches() {
    const token = localStorage['token'];

    this.matchesService.getAllConnections(token).subscribe(
      (res: any) => {
        this.connections = res.data;
        this.filterConnections();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getConnectionsOverview() {
    const token = localStorage['token'];

    this.matchesService.getConnectionsOverview(token).subscribe(
      (res: any) => {
        this.totalOverview = res.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteConnection(id: any) {
    const token = localStorage['token'];

    this.matchesService.deleteConnection(token, id).subscribe(
      (res: any) => {
        this.toastrService.success('Connection deleted successfully');
        this.getMatches();
        this.getConnectionsOverview();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onTableDataChange(event: any) {
    this.p = event;
  }

  private drag(simulation: any): any {
    function dragstarted(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event: any, d: any) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    return d3
      .drag()
      .on('start', dragstarted)
      .on('drag', dragged)
      .on('end', dragended);
  }

  // private linkArc(d: any) {
  //   const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
  //   return `
  //     M${d.source.x},${d.source.y}
  //     A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
  //   `;
  // }

  private linkArc(d: any, i: number, links: any[]) {
    const x1 = d.source.x;
    const y1 = d.source.y;
    const x2 = d.target.x;
    const y2 = d.target.y;

    const numLinks = links.filter(
      (link) =>
        (link.source.id === d.source.id && link.target.id === d.target.id) ||
        (link.source.id === d.target.id && link.target.id === d.source.id)
    ).length;

    const linkIndex = links
      .filter(
        (link) =>
          (link.source.id === d.source.id && link.target.id === d.target.id) ||
          (link.source.id === d.target.id && link.target.id === d.source.id)
      )
      .indexOf(d);

    const curve = linkIndex - numLinks / 2;

    const dx = x2 - x1;
    const dy = y2 - y1;
    const dr = Math.sqrt(dx * dx + dy * dy) / (1 + (1 / numLinks) * curve);

    return `M${x1},${y1}A${dr},${dr} 0 0,${curve > 0 ? 1 : 0} ${x2},${y2}`;
  }

  showUserOverview(id: number) {
    if (this.svg) {
      this.svg = null;
      document.getElementById('connections-chart')!.innerHTML = '';
    }

    const rows = this.connections.filter(
      (d) => d.sender_id == id || d.receiver_id == id
    );
    const data = [];
    this.overview = {
      name: '',
      mutualConnections: 0,
      passedConnections: 0,
      sentConnections: 0,
      maybeConnections: 0,
      receivedConnections: 0,
      pendingConnections: 0,
      newConnections: 0,
      duplicates: 0,
    };

    const duplicatedRecords: any[] = [];

    for (const row of rows) {
      if (row.sender_id == id) {
        this.overview.name = row.sender_name;
      } else {
        this.overview.name = row.receiver_name;
      }

      if (row.sender_status || row.receiver_status) {
        if (row.sender_status) {
          data.push({
            source: row.sender_name,
            target: row.receiver_name,
            type: row.sender_status,
          });
        }
        if (row.receiver_status) {
          data.push({
            source: row.receiver_name,
            target: row.sender_name,
            type: row.receiver_status,
          });
        }
      } else {
        data.push({
          source: row.sender_name,
          target: row.receiver_name,
          type: 'Pending',
        });
      }

      if (row.sender_id == id) {
        if (row.sender_status == 'Yes') {
          if (row.receiver_status == 'Yes') {
            this.overview.mutualConnections += 1;
          }
          if (row.receiver_status == 'Maybe' || !row.receiver_status) {
            this.overview.sentConnections += 1;
          }
        } else if (row.sender_status == 'Maybe') {
          this.overview.maybeConnections += 1;
        } else if (row.sender_status == 'Pass') {
          this.overview.passedConnections += 1;
        } else {
          this.overview.newConnections += 1;
        }
      } else {
        if (row.sender_status == 'Yes') {
          if (row.receiver_status == 'Yes') {
            this.overview.mutualConnections += 1;
          }
          if (row.receiver_status == 'Maybe') {
            this.overview.maybeConnections += 1;
          }
          if (row.receiver_status == 'Pass') {
            this.overview.passedConnections += 1;
          }
          if (!row.receiver_status) {
            this.overview.receivedConnections += 1;
          }
        }
      }

      for (const item of rows) {
        if (item.id != row.id) {
          if (
            (item.sender_id == row.sender_id &&
              item.receiver_id == row.receiver_id) ||
            (item.sender_id == row.receiver_id &&
              item.receiver_id == row.sender_id)
          ) {
            if (
              (item.sender_status || item.receiver_status) &&
              item.sender_status !== 'Maybe'
            ) {
              if (!duplicatedRecords.includes(item.id)) {
                duplicatedRecords.push(item.id);
                this.overview.duplicates += 1;
              }
              if (!duplicatedRecords.includes(row.id)) {
                duplicatedRecords.push(row.id);
                this.overview.duplicates += 1;
              }
            }
            if (!item.sender_status && !item.receiver_status) {
              if (
                item.sender_id == row.sender_id &&
                item.receiver_id == row.receiver_id
              ) {
                this.overview.duplicates += 1;
                duplicatedRecords.push(item.id);
                duplicatedRecords.push(row.id);
              }
            }
          }
        }
      }
    }

    const width = 1200;
    const height = 700;
    const types = ['Yes', 'Maybe', 'Pass', 'Pending'];
    const nodes: any[] = Array.from(
      new Set(data.flatMap((d) => [d.source, d.target])),
      (id) => ({ id })
    );
    const links = data.map((d) => {
      return {
        source: d.source,
        target: d.target,
        type: d.type,
      };
    });

    const color = d3.scaleOrdinal(types, [
      this.linkColors['Yes'],
      this.linkColors['Maybe'],
      this.linkColors['Pass'],
      this.linkColors['Pending'],
    ]);

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink(links)
          .id((d: any) => d.id)
          .distance(30)
      )
      .force('charge', d3.forceManyBody().strength(-2000))
      .force('x', d3.forceX())
      .force('y', d3.forceY());

    const svg = d3
      .select('div#connections-chart')
      .append('svg')
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('width', width)
      .attr('height', height)
      .attr('style', 'max-width: 100%; height: auto; font: 12px sans-serif;');

    svg
      .append('defs')
      .selectAll('marker')
      .data(types)
      .join('marker')
      .attr('id', (d) => `arrow-${d}`)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 15)
      .attr('refY', -0.5)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto')
      .append('path')
      .attr('fill', color)
      .attr('d', 'M0,-5L10,0L0,5');

    const link = svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .selectAll('path')
      .data(links)
      .join('path')
      .attr('stroke', (d) => {
        if (d.type) {
          return this.linkColors[d.type];
        } else {
          return this.linkColors['Pending'];
        }
      })
      .attr(
        'marker-end',
        (d) => `url(${new URL(`#arrow-${d.type}`, location.href)})`
      );

    const node = svg
      .append('g')
      .attr('fill', 'currentColor')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .call(this.drag(simulation));

    node
      .append('circle')
      .attr('stroke', 'white')
      .attr('stroke-width', 1.5)
      .attr('r', 4);

    node
      .append('text')
      .attr('x', 8)
      .attr('y', '0.31em')
      .text((d) => d.id)
      .clone(true)
      .lower()
      .attr('fill', '#00000066')
      .attr('stroke', 'white')
      .attr('stroke-width', 3);

    simulation.on('tick', () => {
      link.attr('d', (d, i) => this.linkArc(d, i, links));
      node.attr('transform', (d) => `translate(${d.x},${d.y})`);
    });

    this.svg = svg;

    $('#viewUserDetails').modal();
  }

  hideUserOverview() {
    $('#viewUserDetails').modal('hide');
  }

  hideUserActions() {
    $('#runUserActions').modal('hide');
  }

  runUserActions() {
    $('#runUserActions').modal('show');
  }

  runAlgorithm() {
    $('#runMatchingAlgorithm').modal('show');
  }

  hideAlgorithm() {
    $('#runMatchingAlgorithm').modal('hide');
  }

  startUserActions() {
    this.runningUserActions = true;

    const token = localStorage['token'];
    this.matchesService.runRandomUserActions(token).subscribe(
      (res: Blob) => {
        this.toastrService.success(
          'Finished random user action simulation successfully'
        );
        this.runningUserActions = false;
        this.hideUserActions();
        this.getMatches();
        this.getConnectionsOverview();

        const url = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'user-actions.csv';
        link.click();
      },
      (err) => {
        console.log(err);
        this.runningUserActions = false;
      }
    );
  }

  startMatchingAlgorithm() {
    this.runningAlgorithm = true;

    const token = localStorage['token'];
    this.matchesService.runMatchingAlgorithm(token).subscribe(
      (res: Blob) => {
        this.toastrService.success(
          'Finished matching algorithm execution successfully'
        );
        this.runningAlgorithm = false;
        this.hideAlgorithm();
        this.getMatches();
        this.getConnectionsOverview();

        const url = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'matching-result.csv';
        link.click();
      },
      (err) => {
        console.log(err);
        this.runningAlgorithm = false;
      }
    );
  }
}
