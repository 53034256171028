import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $ : any;
@Component({
  selector: 'app-smoker-list',
  templateUrl: './smoker-list.component.html',
  styleUrls: ['./smoker-list.component.css']
})
export class SmookerListComponent implements OnInit {

  token: any;
  smokerList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  id: any;
  searchText: any = '';
  notexist: any;
  err: any;
  sideStatus: any;
  isChecked: any;
  bodyTypeId: any = [];
  masterSelected: boolean = false;
  exportList: any;
  constructor(    public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.smokerListing();
  }

  smokerListing() {
    let offset = this.p - 1;
    if(this.searchText != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.getSmokerListing(obj,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.smokerList =  res.data.data;
        this.count = res.data.total_count
        // this.toastr.success(res.message);
        console.log(this.smokerList);
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        // this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.notexist = res.message;
        this.err = res.data
      }
      else{
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.smokerListing()
   }

  searchTextFilter() {
    this.smokerListing()
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.bodyTypeId = []
    this.smokerListing();
  }

  openModal(item: any){
    this.id = item.id;
  }

  deletePop(){
    let obj = {
      id: this.id,
    };
    console.log(obj)
    this.prefrencesService
      .smokerDelete(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.smokerListing();
      });
  }

  works(event:any){
    event.sideName.menuList.forEach((element:any) => {
      if(element.role_name=='Master Data')
      {
        this.sideStatus=element.role_status;
      }
    });
  }

}
