import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-alcohol-edit',
  templateUrl: './alcohol-edit.component.html',
  styleUrls: ['./alcohol-edit.component.css']
})
export class AlcoholEditComponent implements OnInit {
  alcoholEditForm!:FormGroup;
  submitted: any;
  token: any;
  alcoholData: any;
  id:any;
  image: any;
  imageSrc: any;
  fileName!: string;


  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.alcoholEditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
    this.geteditData();
  }

  get editForm() {return this.alcoholEditForm.controls;}

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.image = file;
      this.fileName =  file.name
      this.createImagePreview(file);
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  geteditData(){
    let obj = {
      height_id : this.id
    };
    this.token = localStorage['token'];
    this.prefrencesService.alcoholCunsumptionDetails(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.alcoholData = res.data;
        this.alcoholData = res.data;
        this.alcoholEditForm.patchValue({
          name:this.alcoholData.name,
        });
        const imageName=this.alcoholData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
        this.image = this.alcoholData.image;
        this.alcoholEditForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;
          console.log(this.imageSrc )
        }
        // this.toastr.success(res.message);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onSubmit(){
    this.submitted = true;
    if (this.alcoholEditForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.alcoholEditForm.get('name')?.value)
    formData.append('image',this.image)
    formData.append('id',this.id)
    this.prefrencesService.editAlocoholConsumption(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/alcohol-consumption']);
        this.alcoholEditForm.reset();
        this.submitted = false;
      }
    });
  }
}
