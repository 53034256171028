import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-personalities-add',
  templateUrl: './personalities-add.component.html',
  styleUrls: ['./personalities-add.component.css']
})
export class PersonalityAddComponent implements OnInit {
  personalityAddForm!:FormGroup;
  submitted : any = false;
  token: any;
  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder) { }

  ngOnInit(): void {
    this.personalityAddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
  }

  get addForm() {return this.personalityAddForm.controls}

  image:any
  uploadFile(e:any){
    this.image=e.target.files[0]
    this.personalityAddForm.value.image = this.image
  }

letterOnly(event:any) {
            var charCode = event.keyCode;

            if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8)

                return true;
            else
                return false;
}

  onSubmit(){
    this.submitted = true;
    if (this.personalityAddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.personalityAddForm.get('name')?.value)
    formData.append('image',this.image)
    this.prefrencesService.personalityAdd(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/personality']);
        this.personalityAddForm.reset();
        this.submitted = false;
      }
    });
  }

}
