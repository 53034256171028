import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RgtService } from 'src/app/services/rgt.service';
import { countries } from "countries-list";
import type { TCountryCode } from "countries-list"
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
declare var $: any;

@Component({
  selector: 'app-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.css']
})
export class RegionListComponent implements OnInit {
  editing: boolean = false;
  submitted: boolean = false;
  token: any;
  regionList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  err: any;
  limit: any = 10;
  offset: any;
  id: any = "";
  regionName: string = "";
  regionCountry: any = "";
  regionStatus: any = "ACTIVE";
  dataCountryList: Select2Data = [];
  dataStatusList: Select2Data = [
    {
      "label": "",
      "options": [
        {
          "value": "ACTIVE",
          "label": "Active",
        },
        {
          "value": "INACTIVE",
          "label": "Inactive",
        }
      ],
    },
  ]

  constructor(
    public router: Router,
    private rgtService: RgtService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.regionListing()

    const countryCodes = Object.keys(countries);
    let countryList: any = (countryCodes.map((code) => {
      return countries[code as TCountryCode].name;
    })).sort();

    countryList = countryList.map((country: any) => {
      return {
        value: country,
        label: country
      }
    });

    this.dataCountryList = [{
      "label": "",
      "options": countryList
    }]
  }

  regionListing() {
    this.offset = this.p - 1;
    let obj = {
      limit: this.total,
      offset: this.offset,
    };
    this.token = localStorage['token'];

    this.rgtService.regionList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.regionList = res.data.data;
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  initModal() {
    this.submitted = false;
    this.editing = false;
    this.regionName = "";
    this.regionCountry = "";
    this.regionStatus = "ACTIVE";
  }

  openModal(id?: any, editingModal?: boolean) {
    this.initModal();

    if (id) {
      this.id = id;
    }

    if (editingModal === true) {
      this.editing = true;

      const region = this.regionList.find((item: any) =>
        item.id == id
      );

      this.regionName = region?.name;
      this.regionCountry = region?.country;
      this.regionStatus = region?.status;
    }
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.regionListing();
  }

  updateCountry(event: Select2UpdateEvent) {
    this.regionCountry = event.value;
  }

  updateStatus(event: Select2UpdateEvent) {
    this.regionStatus = event.value;
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.regionName)
    console.log(this.regionCountry)
    console.log(this.regionStatus)
    if (this.regionName == "" || this.regionCountry == "") {
      return;
    }

    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('id', this.id)
    formData.append('name', this.regionName)
    formData.append('country', this.regionCountry)
    formData.append('status', this.regionStatus)

    if (this.editing === true) {
      this.rgtService.updateRegion(formData, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message)
          this.regionListing();
          $("#upsertRegionModal").modal("hide");
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.rgtService.createRegion(formData, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message)
          this.regionListing();
          $("#upsertRegionModal").modal("hide");
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  }

  onDelete() {
    this.token = localStorage['token'];
    let obj = {
      id: this.id,
    };

    this.rgtService.deleteRegion(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        $("#deleteRegionModal").modal("hide");
        this.regionListing();
      } else {
        this.toastr.error(res.message);
      }
    });
  }
}
