import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
  selector: 'app-promo-code-list',
  templateUrl: './promo-code-list.component.html',
  styleUrls: ['./promo-code-list.component.css']
})
export class PromoCodeListComponent {
  token: any;
  promoData: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  status: any;
  id: any = '';
  search: any = '';
  fromdate: any = '';
  todate: any = '';
  userId: any = [];
  notexist: any;
  submitted: any;
  err: any;
  exportList: any;
  isUserStatus: String = 'approved'

  constructor(
    public router: Router,
    private toastr: ToastrService,
    public promoService: PromoCodeService
  ) {
    this.router.getCurrentNavigation()?.extras;
  }

  ngOnInit(): void {
    this.promoListing();
  }

  promoListing() {
    let offset = this.p - 1;
    if (this.search != '') {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.search
    };
    this.token = localStorage['token'];
    this.promoService.promoList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.promoData = res.data.data;
      }
    })
  }

 
  onKeyUpEvent(event:any) {
    this.search = event.target.value;
    this.promoListing()
  }


  onTableDataChange(event: any) {
    alert('HHHH')
    this.p = event;
    this.promoListing();
  }

 

}
