import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd  , Event} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';
import { PushNotificationService } from 'src/app/services/push-notification.service';

@Component({
  selector: 'app-notification-add',
  templateUrl: './notification-add.component.html',
  styleUrls: ['./notification-add.component.css']
})
export class NotificationAddComponent {
  addnotificationForm!: FormGroup;
  submitted = false;
  token: any;
  userList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  fromdate: any = '';
  todate: any = '';
  showList: Boolean = false;
  search: any = '';
  userId: any = [];
  isChecked: any;
  isUserStatus:String='approved';
  unapprovedUserList:any;
  masterSelected: boolean = false;
  constructor(
    public router: Router,
    private pushnotification: PushNotificationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activateroute: ActivatedRoute,
    private customerService: CustomerService
  ) {
  }

  ngOnInit(): void {
    this.addnotificationForm = this.formBuilder.group({
      Title: ['', Validators.required],
      Body: ['', Validators.required],
      userType: ['', Validators.required],
    });
    this.userListing();
  }

  get addf() {
    return this.addnotificationForm.controls;
  }

  tableShow(type: any) {
    if (type == 'Individual') {
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  // checkItem(event: any, item: any) {
  //   this.userId = item.user_id;
  // }

  checkUncheckAll(event:any) {
    let checkAll = event.target.checked;
    this.masterSelected = checkAll;
    if(this.masterSelected){
      this.userList.forEach((element:any) => {
        console.log(element , 'AAAAAAAAAAAAA')
        this.userId.push(element.id)
      });
    }
    else{
      this.userId = [];
    }
  }


  // checkItem(event: any, item: any) {
  //   this.isChecked = event.target.checked;
  //   if (this.isChecked) {
  //     if (this.userId.indexOf(item.id) == -1) {
  //       this.userId.push(item.id);
  //     }
  //   } else {
  //     let index = this.userId.indexOf(item.id);
  //     this.userId.splice(index, 1);
  //   }
  // }

  checkMenu(event: any, id: any) {
    this.isChecked = event.target.checked;
    if (this.isChecked) {
      if (this.userId.indexOf(id) == -1) {
        this.userId.push(id);
      }
    } else {
      let index = this.userId.indexOf(id);
      this.userId.splice(index, 1);
    }
  }

  checkFun(Id:any){
   return this.userId.includes(Id)
  }

  onKeyUpEvent(event: any) {
    this.search = event.target.value;
    this.userListing();
  }

  userListing() {
    let offset = this.p - 1;
    let obj = {
      limit: 10,
      offset: offset,
      fromdate: this.fromdate,
      todate: this.todate,
      search: this.search,
    };
    this.token = localStorage['token'];
    if(this.isUserStatus=='approved'){
      this.unapprovedUserList=[]
      this.customerService.customerList(obj, this.token).subscribe((res: any) => {
        if (res &&  res.status == 200) {
          this.count = res.data.total_count;
          this.userList = res.data.data
        }
        else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
    }
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.userListing();
  }



  onSubmit() {
    this.submitted = true;
    if (this.addnotificationForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let obj = {
      title: this.addnotificationForm.get('Title')?.value,
      message: this.addnotificationForm.get('Body')?.value.replace(/<\/?[^>]+(>|$)/g, ''),
      user_type: this.addnotificationForm.get('userType')?.value,
      ids: this.userId,
    };
    console.log(obj , 'OOOOOOOOo')
    this.pushnotification
      .pushnotificationAdd(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message);
          this.addnotificationForm.reset();
          this.router.navigate(['/push-notification']);
          this.submitted = false;
        } else {
          this.toastr.error(res.message);
        }
      });
  }
}