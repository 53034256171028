import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(public http: HttpClient) { }
  baseUrl = environment.baseUrl

  paymentList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getPaymentList?limit=${obj.limit}&offset=${obj.offset}${obj.region_id ? '&region_id=' + obj.region_id : ""}${obj.group_id ? '&group_id=' + obj.group_id : ""}${obj.tier_id ? '&tier_id=' + obj.tier_id : ""}`, httpOptions);
  }
}
