<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- Dashboard HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      Roles and Access
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="common_btn">
          <button class="add-user-btn" data-toggle="modal" data-target="#addAccessModal" (click)="openModal()">
            <img src="assets/images/plus.svg" alt=""> Add Access
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
  <div class="listingformat">
    <div class="tabledatawrap table-responsive">
      <table>
        <thead>
          <tr class="trGroup">
            <th>Name</th>
            <th>Email Address</th>
            <th>Status</th>
            <th>Role</th>
            <th>Access To</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody
          *ngFor="let item of adminList | paginate: {id: 'admins', itemsPerPage: total, currentPage: p , totalItems: count}; let i = index">
          <tr>
            <td class="width15">{{ item.name || "NA" }}</td>
            <td class="width15">{{ item.email || "NA" }}</td>
            <td class="width15"><span [class]="item?.status.toLowerCase()">{{ item.status || "NA" }}</span></td>
            <td class="width15">{{ item.role_name || "NA" }}</td>
            <td><span class="accessLevel">{{ item.role == "SUPERADMIN" ? "Global" : (item.region_name ||
                item.group_name) }}</span></td>
            <td class="width20">
              <div class="action-btns hide-profile-btn">
                <button *ngIf="item?.status=='ACTIVE'" (click)="openModal(item.id); action='block'" data-toggle="modal"
                  data-target="#blockUnblockDeleteModal" data-placement="top" title="Block User">
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </button>
                <button *ngIf="item?.status=='INACTIVE'" (click)="openModal(item.id); action='unblock'"
                  data-toggle="modal" data-target="#blockUnblockDeleteModal" data-placement="top" title="Unblock User">
                  <i class="fa fa-ban" aria-hidden="true"></i>
                </button>
                <button (click)="openModal(item.id)" data-toggle="modal" data-target="#sendEmailModal"
                  data-placement="top" title="Send Email">
                  <i class="far fa-envelope" aria-hidden="true"></i>
                </button>
                <button (click)="openModal(item.id); action='delete'" data-toggle="modal"
                  data-target="#blockUnblockDeleteModal" data-placement="top" title="Delete User">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="adminList.length==0">
          <tr>
            <td colspan="5">No Data Found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 custompagination">
        <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="admins"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!-- Add Access Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="addAccessModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Add Access
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <form>
            <div class="faq-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Name</label>
                      <span>
                        <input name="name" [(ngModel)]="name" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Name">
                        <div class="errorDiv" *ngIf="submitted && !name" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Email Address</label>
                      <span>
                        <input name="email" [(ngModel)]="email" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Email Address">
                        <div class="errorDiv" *ngIf="submitted && !email" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Role</label>
                      <span>
                        <select2 [data]="dataRoleList" [value]="role" minCountForSearch="0" noResultMessage="No result"
                          (update)="updateRole($event)">
                        </select2>
                        <div class="errorDiv" *ngIf="submitted && !role" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Access Level</label>
                      <span>
                        <select2 [data]="dataAccessLevelList" [value]="accessLevel" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateAccessLevel($event)">
                        </select2>
                        <div class="errorDiv" *ngIf="submitted && !accessLevel" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="common_btn">
              <button data-dismiss="modal">Back</button>
              <button id="close" (click)="onSubmit()">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Send Email Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="sendEmailModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Send Email With Updated Password
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <p> Are you sure you want to send email with the updated password to this user?</p>
          <div class="common_btn">
            <button data-dismiss="modal">Cancel</button>
            <button id="close" (click)="onEmailSubmit()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Block/Unblock/Remove Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="blockUnblockDeleteModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          {{ action | titlecase }}
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <p> Are you sure you want to {{action}} this user?</p>
          <div class="common_btn">
            <button data-dismiss="modal">Cancel</button>
            <button id="close" (click)="onBlockUnblockDeleteSubmit()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>