import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
declare var $ : any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  profileName:string | undefined | any
  constructor(public router :Router , private route : ActivatedRoute,private loginService:LoginService) { }

  ngOnInit(): void {
    this.loginService.profileSubject.subscribe((res)=>{
      this.profileName = res
    })
  }

  logout() {
    localStorage.removeItem('admin_id');
    localStorage.clear();
    $('#logModal').modal('hide');
    this.router.navigate(['/login']);
  }
}
