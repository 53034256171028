import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaticContentService {
  constructor(public http : HttpClient) { }
  baseUrl = environment.baseUrl

  aboutContent(token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/getAboutUs`,httpOptions);
  }

  updateContent(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `/CreateUpdateAboutUs`,obj, httpOptions);
  }

  privacyContent(token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getPrivacyPolicy`,httpOptions);
  }


  privacyupdateContent(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `createUpdatePrivacyPolicy`,obj, httpOptions);
  }

  contactusContent(token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getContactUs`,httpOptions);
  }

  contactusupdateContent(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `createUpdateContactUs`,obj, httpOptions);
  }
  

  termConditionContent(token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/getTermsAndCondition`,httpOptions);
  }


  termConditionupdateContent(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `/createUpdateTermsAndCondition`,obj, httpOptions);
  }



}
