import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [],
  exports: [HeaderComponent,CommonModule, SharedModule],
  declarations: [HeaderComponent],
  providers: [],
})
export class HeaderModule {

}
