import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $ : any;
@Component({
  selector: 'app-create-by-list',
  templateUrl: './hobbie-list.component.html',
  styleUrls: ['./hobbie-list.component.css']
})
export class HobbieListComponent implements OnInit {

  token: any;
  createList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  hobbie_id: any;
  searchText: any = '';
  sideStatus: any;
  isChecked: any;
  createId: any = [];
  masterSelected: boolean = false;
  exportList: any;

  constructor(    public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.createListing();
  }

  createListing() {
    let offset = this.p - 1;
    if(this.searchText != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.hobbieListing(obj,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.createList =  res.data.data;
        this.count = res.data.total_count;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        // this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else{
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.createListing()
   }

   stopSearchLoader() {
    $('.lds-ring').css('display', 'none');
  }

  searchTextFilter() {
    this.createListing()
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.createId = [];
    this.createListing();
  }

  openModal(item: any){
    this.hobbie_id = item.id;
  }

  deletePop(){
    let obj = {
      id: this.hobbie_id,
    };
    console.log(obj)
    this.prefrencesService
      .createByDelete(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.createListing();
      });
  }

}
