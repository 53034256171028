<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- Dashboard HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      Partnerships
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="common_btn">
          <button class="add-user-btn" data-toggle="modal" (click)="openModal()" data-target=" #upsertPartnershipModal">
            <img src="assets/images/plus.svg" alt=""> Add Partnership
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-md-2 pr-0">
      <select2 [data]="dataRegionList" [value]="region" minCountForSearch="0" noResultMessage="No result"
        placeholder="Select Region" (update)="updateRegion($event)">
      </select2>
    </div>
    <div class="col-md-2 pr-0">
      <select2 [data]="dataGroupList" [value]="group" minCountForSearch="0" noResultMessage="No result"
        placeholder="Select Group" (update)="updateGroup($event)">
      </select2>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
  <div class="listingformat">
    <div class="tabledatawrap table-responsive">
      <table>
        <thead>
          <tr class="trPartnership">
            <th>Order</th>
            <th>Name</th>
            <th>Title</th>
            <th>Location</th>
            <th>Discount</th>
            <th>Description</th>
            <th>Image</th>
            <th>URL</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
          let item of partnershipList | paginate: {id: 'partnerships', itemsPerPage: total, currentPage: p , totalItems: count}; let i = index;">
            <td class="width10">
              {{ item.order || "NA" }}
            </td>
            <td class="width20">
              {{ item.name }}
            </td>
            <td class="width20">
              {{ item.title }}
            </td>
            <td class="width15">
              {{ item.location }}
            </td>
            <td class="width10">
              {{ item.discount }} %
            </td>
            <td class="width20">
              <span class="text-eclipse">{{ item.description }}</span>
            </td>
            <td class="width10">
              <img class='table-image-width' src="{{item.image || '/assets/images/Ellipse 24@2x'}}" />
            </td>
            <td class="width10">
              <a target="_blank" [href]="item.site_url">{{ item.site_url }}</a>
            </td>
            <td class="width15">
              <span [class]="item?.status?.toLowerCase()">{{ item.status || "NA" }}</span>
            </td>
            <td class="width15">
              <div class="action-btns hide-profile-btn">
                <button (click)="openModal(item.id, true)" data-toggle="modal" data-target="#upsertPartnershipModal"
                  data-placement="top" title="Edit">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                </button>
                <button (click)="openModal(item.id)" data-toggle="modal" data-target="#deletePartnershipModal"
                  data-placement="top" title="Delete">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="partnershipList?.length==0">
          <tr>
            <td colspan="10">No Data Found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 custompagination">
        <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="partnerships"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!-- Add Partnership Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="upsertPartnershipModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          {{ editing ? "Edit" : "Add"}} Partnership
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <form>
            <div class="faq-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Availability <span>*</span></label>
                      <span>
                        <select2 [data]="dataAvailabilityTypeList" [value]="availabilityType" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateAvailabilityType($event)">
                        </select2>
                      </span>
                      <span class="availability">
                        <select2 [data]="dataAvailabilityList" [value]="availability" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateAvailability($event)">
                        </select2>
                        <div class="errorDiv" *ngIf="submitted && !availability" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Name <span>*</span></label>
                      <span>
                        <input name="name" [(ngModel)]="name" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Name">
                        <div class="errorDiv" *ngIf="submitted && !name" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Title <span>*</span></label>
                      <span>
                        <input name="title" [(ngModel)]="title" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Title">
                        <div class="errorDiv" *ngIf="submitted && !title" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Location <span>*</span></label>
                      <span>
                        <input name="location" [(ngModel)]="location" size="40" class="form-control"
                          aria-required="true" aria-invalid="false" type="text" autocomplete="off"
                          placeholder="Location">
                        <div class="errorDiv" *ngIf="submitted && !location" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Discount <span>*</span></label>
                      <span>
                        <input name="discount" type="number" [(ngModel)]="discount" size="40" class="form-control"
                          aria-required="true" aria-invalid="false" type="text" autocomplete="off"
                          placeholder="Discount">
                        <div class="errorDiv" *ngIf="submitted && !discount" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Description <span>*</span></label>
                      <span>
                        <textarea rows="4" cols="50" name="description" size="40" class="form-control"
                          aria-required="true" aria-invalid="false" autocomplete="off" placeholder="Description"
                          [(ngModel)]="description" autocomplete='off' required>
                         </textarea>
                        <div class="errorDiv" *ngIf="submitted && !description" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Site URL</label>
                      <span>
                        <input name="site_url" [(ngModel)]="siteUrl" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Site URL">
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label> Attach Image</label>
                      <span>
                        <input #fileInput name="image" value="" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="file" autocomplete="off" placeholder="Image" autocomplete='off'
                          (change)="onImageSelected($event)" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Gender</label>
                      <span>
                        <select2 [data]="dataGenderList" [value]="gender" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateGender($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Restrictions</label>
                      <span>
                        <select2 [data]="dataRestrictionsList" [value]="restrictions" minCountForSearch="0"
                          multiple="true" noResultMessage="No result" (update)="updateRestrictions($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Status</label>
                      <span>
                        <select2 [data]="dataStatusList" [value]="status" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateStatus($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="common_btn">
              <button data-dismiss="modal">Back</button>
              <button id="close" (click)="onSubmit()">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Partnership Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="deletePartnershipModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Delete
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <p> Are you sure you want to delete this partnership?</p>
          <div class="common_btn">
            <button data-dismiss="modal">Cancel</button>
            <button (click)="onDelete()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>