<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>FAQs</h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="common_btn">
                    <a href="javascript:void(0);" routerLink="/faq-add"><button class="add-user-btn"
                            [disabled]="this.sideStatus == 'Read' "><img src="assets/images/plus.svg" alt=""> Add
                            FAQ</button></a>
                </div>
            </div>

        </div>
    </div>
    <div class="listingformat">
        <div class="tabledatawrap preferences-table">
            <table>
                <thead>
                    <tr>
                        <th>Question (English)</th>
                        <th>Answer (English)</th>
                        <th colspan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of faqList | paginate: {id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count};let i = index;">
                        <td><span class="text-eclipse">{{item.question}}</span> </td>
                        <td> <span class="text-eclipse"> {{item.answer}} </span> </td>
                        <td>
                            <div class="action-btns" [routerLink]="['/faq-edit', item.id]">
                                <button class="anchortag" [disabled]="this.sideStatus == 'Read'">Edit</button>
                            </div>

                        </td>
                        <td>
                            <div class="action-btns" data-toggle="modal" data-target="#deletModal"
                                (click)="openModal(item)">
                                <button class="anchortag" [disabled]="this.sideStatus == 'Read'">Delete</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="faqList?.length == 0">
                    <tr>
                        <td colspan="12">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 custompagination">
                <pagination-controls class="" id="delivered" (pageChange)="onTableDataChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!-- Delete Popup -->
<div class="modal fade bd-example-modal-sm common_modal" id="deletModal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog animated fadeInDown">
        <div class="modal-content">
            <div class="modal-header">
                <div class="popupheding">
                    Delete
                </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="filtercard">
                    <p>Are you sure you want to delete this?</p>
                    <div class="common_btn">
                        <button data-dismiss="modal">Cancel</button>
                        <button (click)="deletePop()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Popup -->