import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-life-goals-add',
  templateUrl: './life-goals-add.component.html',
  styleUrls: ['./life-goals-add.component.css']
})
export class LifeGoalsAddComponent implements OnInit {

  lifeGoalsAddForm!:FormGroup;
  submitted: any;
  token: any;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {

    this.lifeGoalsAddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });

  }


  get addForm() {return this.lifeGoalsAddForm.controls;}

  image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
    this.lifeGoalsAddForm.value.image= this.image
}

  onSubmit(){
    this.submitted = true;
    if (this.lifeGoalsAddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.lifeGoalsAddForm.get('name')?.value)
    formData.append('image', this.image)
    this.prefrencesService.addLifeGoals(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/life-goals-list']);
        this.lifeGoalsAddForm.reset();
        this.submitted = false;
      }
    });
  }

}
