<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- FAQ HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      <span routerLink="/push-notification" class="breadcumbs">Push Notification</span> <span
        class="navigation-arrow">></span>
      <span>Send Push Notification</span>
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="common_btn">
          <a href="javascript:void(0);" routerLink="/push-notification"><button class="export-btn">Cancel</button></a>
          <a href="javascript:void(0);">
            <button (click)="onSubmit()">Send</button></a>
        </div>
      </div>
    </div>
  </div>
  <div class="add-data-form faq-wrap">
    <div class="row">
      <div class="col-md-6"  [formGroup]="addnotificationForm">
        <div class="form-group full-input-wrap">
          <p>
            <label>Title <span>*</span></label>
            <span class="your-name"><input name="your-name" placeholder="Title" value="" size="40" class="form-control"
                type="text" autocomplete="off" autocomplete="off" formControlName="Title" [ngClass]="{
                  'is-invalid': submitted && addf['Title'].errors
                }" />
              <div class="errorDiv" *ngIf="submitted && addf['Title'].errors" class="invalid-feedback">
                <div class="error" *ngIf="addf['Title'].errors['required']">
                  Title is required
                </div>
              </div>
            </span>
          </p>
        </div>
        <div class="form-group full-input-wrap">
          <p>
            <label>Body <span>*</span></label>
            <span>
              <textarea class="form-control" autocomplete="off" placeholder="Body" name="" id="" autocomplete="off"
                cols="30" rows="10" formControlName="Body" [ngClass]="{
                'is-invalid': submitted && addf['Body'].errors
              }"></textarea>
              <div class="errorDiv" *ngIf="submitted && addf['Body'].errors" class="invalid-feedback">
                <div class="error" *ngIf="addf['Body'].errors['required']">
                  Body is required
                </div>
              </div>
            </span>
          </p>
        </div>
        <div class="permissions-wrap">
          <h2>User Type</h2>
          <div class="">
            <div class="right-permission">
              <div class="custom-radio">
                <input type="radio" id="AllUser" formControlName="userType" name="userType" value="All"
                  (change)="tableShow('all')" />
                <label for="AllUser">All Users</label>
              </div>
              <div class="custom-radio">
                <input type="radio" id="individual" formControlName="userType" name="userType" value="Specific"
                  (change)="tableShow('Individual')" />
                <label for="individual">Individual</label>
              </div>
            </div>
            <div class="errorDiv" *ngIf="submitted && addf['userType'].errors" class="invalid-feedback">
              <div class="error" *ngIf="addf['userType'].errors['required']">
                User Type is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="listingformat" *ngIf="showList">
          <div class="search-wrap">
            <input class="form-control" placeholder="Search Customer" type="text" autocomplete="off" id="shares"
              (keyup)="onKeyUpEvent($event)" />
            <i class="fas fa-search"></i>
          </div>
          <div class="tabledatawrap table-responsive">
            <table>
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                      (change)="checkUncheckAll($event)" />
                  </th>
                  <th>User ID</th>
                  <th>Username</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                let item of userList
                  | paginate
                    : {
                        id: 'delivered',
                        itemsPerPage: total,
                        currentPage: p,
                        totalItems: count
                      };
                let i = index
              ">
                  <!-- [checked]=[userId.includes(item.user_id)] -->
                  <!-- <td> 
                <div class="custom-checkbox">
                <input type="checkbox" [checked]="checkFun(item.id)"  id="{{ item.id}}" (change)="checkItem($event,item)">
                <label for="{{ item.id}}">
                </label>
              </div> -->

                  <td>
                    <div class="custom-checkbox">
                      <input type="checkbox" id="{{ item.id }}" [checked]="masterSelected || checkFun(item.id)"
                        (change)="checkMenu($event, item.id)" />
                      <label for="{{ item.id }}"></label>
                    </div>
                  </td>
                  <td>#{{ item.id || "NA" }}</td>
                  <td>{{ item.name || "NA" }}</td>
                  <td>{{ item.email || "NA" }}</td>
                </tr>
              </tbody>
              <tbody *ngIf="userList?.length == 0">
                <tr>
                  <td colspan="12">No Data Found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-12 custompagination">
              <pagination-controls previousLabel="Prev" nextLabel="Next" id="delivered"
                (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
            <!-- <div class="col-md-12 custompagination">
                  <pagination-controls class="" id="delivered" (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Middle Content CSS End Here  -->