import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  constructor(public http : HttpClient) { }
  baseUrl = environment.baseUrl

  refferList(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/getReferUserList?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  exportrefer(token:any){
    // var httpOptions;
    // httpOptions = {
    //   headers: new HttpHeaders({
    //     "accessToken":token
    //   })
    // }
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(this.baseUrl + `/exportReferUserList`, httpOptions);
  }
}
