import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-meet-vibes-add',
  templateUrl: './meet-vibes-add.component.html',
  styleUrls: ['./meet-vibes-add.component.css']
})
export class MeetVibesAddComponent implements OnInit {
  meetVibesAddForm!:FormGroup;
  submitted : any = false;
  token: any;
  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder) { }

  ngOnInit(): void {
    this.meetVibesAddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
        image:['',[Validators.required]]
      });
  }

  get addForm() {return this.meetVibesAddForm.controls}

  image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
}
  onSubmit(){
    this.submitted = true;
    if (this.meetVibesAddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.meetVibesAddForm.get('name')?.value)
    formData.append('image',this.image)

    this.prefrencesService.addMeetVibes(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/meet-vibes-list']);
        this.meetVibesAddForm.reset();
        this.submitted = false;
      }
    });
  }

}
