import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-smoker-add',
  templateUrl: './smoker-add.component.html',
  styleUrls: ['./smoker-add.component.css']
})
export class SmokerAddComponent implements OnInit {
  smokerAddForm!:FormGroup;
  submitted: any;
  token: any;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.smokerAddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['',[Validators.required]]
    });
  }


  get addForm() {return this.smokerAddForm.controls;}

  image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
}
  onSubmit(){
    this.submitted = true;
    if (this.smokerAddForm.invalid) {
      return;
    }   
    this.token = localStorage['token'];
   let formData = new FormData()
   formData.append('name',this.smokerAddForm.get('name')?.value)
   formData.append('image', this.image)
    this.prefrencesService.addSmoker(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/smoker']);
        this.smokerAddForm.reset();
        this.submitted = false;
      }
    });
  }
}
