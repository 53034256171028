<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <div class="globalheading dflex dflex_bet">
        <h2>Dashboard</h2>
    </div>
    <!-- Dashboard HTML Start Here  -->
    <div class="dashboard_wrap">
        <div class="transction-wrap">
            <div class="row">
                <div class="col-md-6">
                    <div class="total-box-div">
                        <div class="filterdiv d-flex">
                            <div class="form-group full-input-wrap">
                                <select class="form-control" (change)="totUser($event)">
                                    <option value="year">This Year</option>
                                    <option value="month">Month</option>
                                    <option value="week">Week</option>
                                    <option value="days">Days</option>
                                </select>
                            </div>
                            <div class="datepicker d-flex" *ngIf="this.filter == 'days'">
                                <div class="datediv">
                                    <p>From Date</p>
                                    <input type="date"  (change)="fromDate($event)" id="startDateDOrder" max="{{ ToDate }}">
                                </div>
                                <div class="datediv">
                                    <p>To Date</p>
                                    <input type="date" (change)="toDate($event)" id="endDateDOrder" max="{{ maxDate }}" min="{{ FromDate }}">
                                </div>
                            </div>

                        </div>
                        <h3>Total User</h3>
                        <p>{{totalUsers?.total_user}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="total-box-div">
                        <div class="filterdiv d-flex">
                            <div class="form-group full-input-wrap">
                                <select class="form-control" (change)="totAppUser($event)">
                                    <option value="year">This Year</option>
                                    <option value="month">Month</option>
                                    <option value="week">Week</option>
                                    <option value="days">Days</option>
                                </select>
                            </div>
                            <div class="datepicker d-flex" *ngIf="this.filterApp == 'days'">
                                <div class="datediv">
                                    <p>From Date</p>
                                    <input type="date"  (change)="fromDatee($event)" id="startDateDOrder" max="{{ ToDate }}">
                                </div>
                                <div class="datediv">
                                    <p>To Date</p>
                                    <input type="date" (change)="toDatee($event)" id="endDateDOrder" max="{{ maxDate }}" min="{{ FromDate }}">
                                </div>
                            </div>
                            <div class="form-group full-input-wrap mg_lft">
                                <select class="form-control" (change)="userChange($event)">
                                    <option value="Approved">Approved</option>
                                    <option value="Unapproved">Unapproved</option>
                                </select>
                            </div>
                        </div>
                        <h3>Total Approve User</h3>
                        <p>{{totalAppUsers?.total_user}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="total-box-div">
                        <div class="filterdiv d-flex">
                            <div class="form-group full-input-wrap">
                                <select class="form-control" (change)="totProUser($event)">
                                    <option value="year">This Year</option>
                                    <option value="month">Month</option>
                                    <option value="week">Week</option>
                                    <option value="days">Days</option>
                                </select>
                            </div>
                            <div class="datepicker d-flex" *ngIf="this.filterPro == 'days'">
                                <div class="datediv">
                                    <p>From Date</p>
                                    <input type="date"  (change)="fromDatePro($event)" id="startDateDOrder" max="{{ ToDate }}">
                                </div>
                                <div class="datediv">
                                    <p>To Date</p>
                                    <input type="date" (change)="toDatePro($event)" id="endDateDOrder" max="{{ maxDate }}" min="{{ FromDate }}">
                                </div>
                            </div>
                            <div class="form-group full-input-wrap mg_lft">
                                <select class="form-control" (change)="userType($event)">
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                </select>
                            </div>
                        </div>
                        <h3>Total Profiles</h3>
                        <p>{{totalPro?.total_user}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="total-box-div">
                        <div class="filterdiv d-flex">
                            <div class="form-group full-input-wrap mg_lft">
                                <select class="form-control" (change)="eveType($event)">
                                    <option value="">Select</option>
                                    <option value="Active">Active</option>
                                </select>
                            </div>
                        </div>
                        <h3>Total Events</h3>
                        <p>{{totalEvents?.total_event}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-graph">
            <div class="row">
                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>User Data Graph</h2>
                            <div class="graph">
                                <canvasjs-chart [options]="userPichartOptions"
                                   ></canvasjs-chart>
                            </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class=" graphbx">
                        <h2>Application Data Graph</h2>
                        <div class="graph">
                                <canvasjs-chart [options]="applicationPichartOptions"
                                   ></canvasjs-chart>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class=" graphbx">
                        <h2>Profile Data Graph</h2>
                        <div class="graph">
                                <canvasjs-chart [options]="profilePiChartOptions"
                                   ></canvasjs-chart>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class=" graphbx">
                        <h2>Member Data Graph</h2>
                        <div class="graph">
                                <canvasjs-chart [options]="memberPiChartOptions"
                                   ></canvasjs-chart>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-6">
                    <div class=" graphbx">
                        <h2>Meet Data Graph</h2>
                        <div class="graph">
                                <canvasjs-chart [options]="meetPiChartOptions"
                                   ></canvasjs-chart>
                           
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Interest Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="interestAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Hobbies Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="hobbiesAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Personality Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="personalityAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Values Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="valueAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Life Goal Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="lifeGoalAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Meet Vibe Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="vibeAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Vibe Questions Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="questionAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                
                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Life Goal Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="maxionGoalAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Habit Analytics</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="habitAnalyticOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>


                <div class="col-md-6">
                    <div class="graphbx">
                        <h2>Preference Count</h2>
                        <div class="graph">
                            <div>
                                <canvasjs-chart [options]="preferenceOptions"></canvasjs-chart>
                              </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->