import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as Editor from 'ckeditor5-custom-build/build/ckeditor';
import * as Editor from 'ckeditor5-custom-build/build/ckeditor';
// import 'ckeditor-custom-styles.css';  // Adjust the path accordingly


import { ToastrService } from 'ngx-toastr';
import { StaticContentService } from 'src/app/services/static-content.service';
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent implements OnInit {
  ckeditor: any = '';
  // public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'bold', 'italic', 'underline', '|',
      'fontColor', 'fontBackgroundColor', '|',
      'undo', 'redo', 'TextColor', 'textColor', '|',
      'TextColor', 'BGColor'
    ],
  };

  // Store the editor content in a variable
  public editorContent: string = '';
  token: any;
  content: any;
  termconditionCont: any;
  sideStatus: any;


  public Editor: any = Editor;

  config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'undo',
      'redo',
      '|',
      'numberedList',
      'bulletedList',
      'PageBreak',
      'inserttable',
      'FontColor',
      'FontSize',
      'FontFamily',
      'FontBackgroundColor',
    ],
    fontSize: {
      options: [9, 11, 13, 'default', 17, 19, 21],
    },
    language: 'es'
  };


  constructor(
    public router: Router,
    private contentService: StaticContentService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getContent();
    // this.Editor.replace('myfield', {
    //   customConfig: {
    //     backgroundColor: 'black',
    //     fontColor: 'white'
    //   }
    // });

    this.Editor.on('instanceCreated', function(e:any) {
      e.editor.addCss( 'body { background-color: red; }' );
  });

  }

  getContent() {
    this.token = localStorage['token'];
    this.contentService.termConditionContent(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.content = res.data;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onChange(event: any) {
    this.termconditionCont = event.editor.getData();
  }

  onSubmit() {
    this.token = localStorage['token'];
    let obj = {
      terms_and_condition: this.termconditionCont,
    };
    this.contentService.termConditionupdateContent(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/terms-condition']);
      } else {
        this.toastr.success(res.message);
      }
    });
  }

  works(event: any) {
    event.sideName.menuList.forEach((element: any) => {
      if (element.role_name == 'Static Content') {
        this.sideStatus = element.role_status;
      }
    });
  }


}
