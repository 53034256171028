import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-maxion-goals-add',
  templateUrl: './maxion-goals-add.component.html',
  styleUrls: ['./maxion-goals-add.component.css']
})
export class MaxionGoalsAddComponent implements OnInit {
  maxionGoalsAddForm!:FormGroup;
  submitted: any;
  token: any;
  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

    ngOnInit(): void {

      this.maxionGoalsAddForm = this.formBuilder.group({
        name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
        description:['',[Validators.required,this.prefrencesService.noSpacesValidator]],
        image:['',[Validators.required]]
      });

    }


    get addForm() {return this.maxionGoalsAddForm.controls;}
    image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
}

    onSubmit(){
      this.submitted = true;
      if (this.maxionGoalsAddForm.invalid) {
        return;
      }
      this.token = localStorage['token'];
      let formData = new FormData()
      formData.append('name',this.maxionGoalsAddForm.get('name')?.value)
      formData.append('image',this.image)
      formData.append('description',this.maxionGoalsAddForm.get('description')?.value)
      this.prefrencesService.addMaxionGoals(formData,this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message);
          this.router.navigate(['/maxion-goals-list']);
          this.maxionGoalsAddForm.reset();
          this.submitted = false;
        }
      });
    }

}
