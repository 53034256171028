<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>Referral Management</h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="search-wrap">
                    <input class="form-control" placeholder="Search" type="text" id="shares"
                        (keyup)="onKeyUpEvent($event)" />
                    <i class="fas fa-search"></i>
                </div>
                <div class="common_btn" >
                    <button class="export-btn" (click)="onExport()">
                        <img src="assets/images/export.svg" alt="" /> Export
                    </button>
                </div>
            </div>

        </div>
    </div>
    <div class="listingformat">
        <div class="tabledatawrap preferences-table table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>Refer By Id</th>
                        <th>Refer By Name </th>
                        <th>Refer By Phone </th>
                        <th>Refer By Email </th>
                        <th>Refer To Id </th>
                        <th>Refer To Name </th>
                        <th>Refer To Phone </th>
                        <th>Refer To Email </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of referList | paginate: {id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count};let i = index;">
                        <td>{{item.refer_by_id}}</td>
                        <td>{{item.refer_by_name}}</td>
                        <td>{{item.refer_by_phone}}</td>
                        <td>{{item.refer_by_email}}</td>
                        <td>{{item.refer_to_id}}</td>
                        <td>{{item.refer_to_name}}</td>
                        <td>{{item.refer_to_phone}}</td>
                        <td>{{item.refer_to_email}}</td>
                        
                    </tr>
                </tbody>
                <tbody *ngIf="referList?.length == 0">
                    <tr>
                        <td colspan="12">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 custompagination">
                <pagination-controls class="" id="delivered" (pageChange)="onTableDataChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

