<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- FAQ HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            <span routerLink="/customers-list" class="breadcumbs">Customer's</span> <span class="navigation-arrow">></span>
            <span>Customer's Feedback</span>
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="search-wrap">
                    <input class="form-control" placeholder="Search" type="text" id="shares"
                        />
                    <i class="fas fa-search"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="listingformat">
        <div class="tabledatawrap preferences-table">
            <table>
                <thead>
                    <tr>
                        <th>Sender Name</th>
                        <th>Meeting</th>
                        <th>Compliment</th>
                        <th colspan="2">Feedback</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
                    let item of listData
                    | paginate: {id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count};let i = index;">
                    <td>{{item.given_by}}</td>
                    <td>{{item.want_maxion_to_plan}}</td>
                    <td>{{item.compliment}}</td>
                    <td>{{item.feedback_question}}</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td colspan="12" class="text-centre">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 custompagination">
                <pagination-controls class="" id="delivered">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>
<!-- Middle Content CSS End Here  -->