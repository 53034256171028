<!-- Header CSS Start Here  -->
<div class="headerwap">
    <nav class="navbar navbar-expand-lg fixed-top">
        <span id="mobsidemenu" class="navbar-toggler-icon"><img src="assets/images/toggle-btn.png" alt=""></span>
        <a class="navbar-brand" href="javascript:void(0)"><img src="assets/images/Maxion-Logo.png" alt=""></a>
        <ul class="navbar-nav navmenuwrap text-right ">
            <!-- <li class="nav-item">
                <div class="search-box">
                    <input type="text" placeholder="Search.." class="input">
                    <img src="assets/images/Union.svg" alt="">
                </div>

            </li> -->
            <li class="nav-item">
                <div class="notification-wrap">
                    <!-- <span class="notificationdot"></span> -->
                    <!-- <img src="assets/images/notification.svg" alt=""> -->
                </div>

            </li>
            <li class="nav-item log_out_popup">
                <a class="dropdown-toggle nav-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="javascript:void(0)"><img src="assets/images/userimg.png" alt=""> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Arrow_Bottom" data-name="Arrow Bottom" transform="translate(0)">
              <rect id="Rectangle_849" data-name="Rectangle 849" width="24" height="24" transform="translate(0)"
                fill="none" />
              <g id="Group_921" data-name="Group 921" transform="translate(2 2)">
                <rect id="Rectangle_850" data-name="Rectangle 850" width="20" height="20" transform="translate(0)"
                  fill="none" />
                <path id="Vector_22_Stroke_" data-name="Vector 22 (Stroke)"
                  d="M.146.154a.478.478,0,0,1,.7,0L3.484,2.945,6.118.154a.478.478,0,0,1,.7,0,.55.55,0,0,1,0,.746L4.188,3.691a.956.956,0,0,1-1.408,0L.146.9A.55.55,0,0,1,.146.154Z"
                  transform="translate(6.032 8)" fill="#a9003c" />
              </g>
            </g>
          </svg>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <!-- <a class="dropdown-item close_overlay" href="#"> <i class="fas fa-user-cog"></i> Account</a> -->
                    <a data-toggle="modal" data-target="#logModal" class="dropdown-item" href="javascript:void(0);"> <i class="fas fa-sign-out-alt"></i> Logout</a>
                </div>
                <div class="overlay"></div>
            </li>
        </ul>
    </nav>
</div>
<div class="sidebaroverlay"></div>
<!-- Header CSS End Here  -->


<!-- Logout Popup -->
<div class="modal fade bd-example-modal-sm common_modal" id="logModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog animated fadeInDown">
        <div class="modal-content">
            <div class="modal-header">
                <div class="popupheding">
                    Logout
                </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="filtercard">
                    <p> Are you sure you want to logout?</p>
                    <div class="common_btn">
                        <button data-dismiss="modal">Cancel</button><button (click)="logout()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Logout Popup -->
