<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <form [formGroup]="interesteditForm">
        <div class="globalheading dflex dflex_bet">
            <h2> <span routerLink="/interest" class="breadcumbs">Interest </span> <span class="navigation-arrow">></span> <span>Edit</span></h2>
            <div class="addbtnhead">
                <div class="page-header-right">
                    <div class="common_btn">
                        <a href="javascript:void(0);" routerLink="/interest"><button class="export-btn">Discard</button></a>
                        <a href="javascript:void(0);"> <button (click)="onSubmit()">Save</button></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-data-form faq-wrap">
            <h2>New Preference</h2>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group full-input-wrap">
                        <p>
                            <label>Name <span>*</span></label>
                            <span class="your-name">
                                <input name="your-name" value="" size="40" class="form-control " aria-required="true"
                                    aria-invalid="false" type="text" autocomplete="off" placeholder="Name"
                                    formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && editForm['name'].errors }" minlength="3"
                                    maxlength="25">
                                    <div class="errorDiv" *ngIf="submitted && editForm['name'].errors"
                                    class="invalid-feedback">
                                    <div class="error" *ngIf="editForm['name'].errors['required']">
                                        Field is required
                                    </div>
                                    <div class="error" *ngIf="editForm['name'].errors['minlength']">
                                         Data must be at least 3 characters long.
                                    </div>
                                    <div class="error" *ngIf="editForm['name'].errors['maxlength']">
                                     Data cannot exceed 25 characters.
                                    </div>
                                    <div *ngIf="editForm['name'].errors['noSpaces']">Data cannot be spaces only.</div>
                                </div>
                            </span>
                        </p>
                    </div>

                    <div class="form-group mt-5">
                        <div class="">
                            <label class="master-data-label">Image <span>*</span></label>
                            <p>
                                <span>
                                <div class="custom-file-input1 w-100">
                                    <label for="fileInput">Choose file</label>
                                    <input type="file" id="fileInput" (change)="onImageSelected($event)" class="form-control"  formControlName="image" accept="image/*"/>
                                    <span>{{ fileName }}</span>
                                </div>
    
                                <div *ngIf="image" class="image-preview-container">
                                    <img [src]="imageSrc" alt="Image Preview" class="image-preview">
                                </div>
                            </span>
                            </p>
                           
                        </div>
                    </div>
                    
                    <!-- <div class="form-group full-input-wrap">
                        <p>
                            <label>Image <span>*</span></label>
                            <span class="your-name">
                                <input name="your-name" value="" size="40" class="form-control " aria-required="true"
                                    aria-invalid="false" type="file" autocomplete="off" placeholder="Image"
                                    formControlName="image" minlength="3"  (change)="onImageSelected($event)">
                            </span>
                        </p>

                        <div *ngIf="image" class="image-preview-container">
                            <img [src]="imageSrc" alt="Image Preview" class="image-preview">
                        </div>
                        
                    </div> -->
                </div>

            </div>
        </div>
    </form>
</div>
<!-- Middle Content CSS End Here  -->
