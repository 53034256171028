<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <form [formGroup]="editsubadminForm">
        <div class="globalheading dflex dflex_bet">
            <h2>
                <span routerLink="/event-list" class="breadcumbs">Event Management</span> <span
                    class="navigation-arrow">&gt;</span>
                <span>Edit Event</span>
            </h2>
            <div class="addbtnhead">
                <div class="page-header-right">
                    <div class="common_btn">
                        <button class="export-btn" [routerLink]="['/event-list']">Cancel</button>
                        <button (click)="onSubmit()">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-admin-add-form">
            <div class="add-data-form">
                <h2>Basic Information</h2>
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Title <span>*</span></label>
                                <span class="your-name">
                                    <input name="your-name" value="" size="40" class="form-control" aria-required="true"
                                        aria-invalid="false" type="text" autocomplete="off" placeholder="Title"
                                        formControlName="title" [ngClass]="{
                          'is-invalid': submitted && editSub['title'].errors
                        }" />
                                    <div class="errorDiv" *ngIf="submitted && editSub['title'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="editSub['title'].errors['required']">
                                            Title is required
                                        </div>
                                        <div *ngIf="editSub['title'].errors['noSpaces']">Data cannot be spaces only.</div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Location <span>*</span></label>
                                <span class="your-name"><input name="your-name" value="" size="40" class="form-control"
                                        aria-required="true" aria-invalid="false" type="text" autocomplete="off"
                                        placeholder="Location" formControlName="location"
                                        [ngClass]="{'is-invalid': submitted && editSub['location'].errors}"
                                        autocomplete='off' id="email" required />
                                    <div class="errorDiv" *ngIf="submitted && editSub['location'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="editSub['location'].errors['required']">
                                            Location is required
                                        </div>
                                        <div *ngIf="editSub['location'].errors['noSpaces']">Data cannot be spaces only.</div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Date & Time<span>*</span></label>
                                <span class="your-name"><input value="" size="40" class="form-control"
                                        aria-required="true" aria-invalid="false" type="datetime-local"
                                        autocomplete="off" placeholder="Date & Time" formControlName="dateTime"
                                        [ngClass]="{'is-invalid': submitted && editSub['dateTime'].errors}"
                                        autocomplete='off' required />
                                    <div class="errorDiv" *ngIf="submitted && editSub['dateTime'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="editSub['dateTime'].errors['required']">
                                            Date & Time is required
                                        </div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap passwordfield">
                            <p>
                                <label>URL <span>*</span></label>
                                <span class="your-name"><input name="your-name" value="" size="40"
                                        class="form-control passwordinput" aria-required="true" aria-invalid="false"
                                        type="text" autocomplete="off" placeholder="URL" formControlName="url"
                                        [ngClass]="{'is-invalid': submitted && editSub['url'].errors}"
                                        autocomplete='off' required />
                                    <div class="errorDiv" *ngIf="submitted && editSub['url'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="editSub['url'].errors['required']">
                                            URL is required
                                        </div>
                                        <div class="error" *ngIf="editSub['url'].errors['pattern']">
                                            Please enter a valid url.
                                        </div>
                                        <div *ngIf="editSub['url'].errors['noSpaces']">Data cannot be spaces only.</div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- <div class="col-md-5">
                        <div class="form-group full-input-wrap passwordfield">
                            <p>
                                <label>Image <span>*</span></label>
                                <span class="your-name">
                                    <input name="your-name" value="" size="40"
                                        class="form-control passwordinput" aria-required="true" aria-invalid="false"
                                        type="file" autocomplete="off" placeholder="Image" formControlName="image"
                                        autocomplete='off' (change)="onImageSelected($event)" />
                                   
                                </span>
                            </p>

                            <div *ngIf="image" class="image-preview-container">
                                <img [src]="imageSrc" alt="Image Preview" class="image-preview">
                            </div>
                        </div>
                    </div> -->

                    <div class="col-md-5 mt-4">
                        <p>
                            <span>
                            <label class="custom-label">Image <span>*</span></label>
                            <div class="custom-file-input1 w-100">
                                <label for="fileInput">Choose file</label>
                                <input #fileInput type="file" id="fileInput" (change)="onImageSelected($event)" class="form-control"  formControlName="image"
                               (change)="onImageSelected($event)"
                                />
                                <span>{{ fileName }}</span>
                            </div>

                            <div *ngIf="image" class="image-preview-container">
                                <img [src]="imageSrc" alt="Image Preview" class="image-preview">
                            </div>
                        </span>
                        </p>
                       
                    </div>

                    <div class="col-md-5">
                        <div class="form-group full-input-wrap passwordfield">
                            <p>
                                <label>Description <span>*</span></label>
                                <span class="your-name">
                                    <textarea rows="4" cols="50" name="your-name" value="" size="40"
                                        class="form-control passwordinput" aria-required="true" aria-invalid="false"
                                        autocomplete="off" placeholder="Description" formControlName="description"
                                        [ngClass]="{'is-invalid': submitted && editSub['description'].errors}"
                                        autocomplete='off' required>
                                    </textarea>


                                    <div class="errorDiv" *ngIf="submitted && editSub['description'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="editSub['description'].errors['required']">
                                            Description is required
                                        </div>
                                        <div *ngIf="editSub['description'].errors['noSpaces']">Data cannot be spaces only.</div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->