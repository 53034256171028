<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- FAQ HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            <span routerLink="" class="breadcumbs">Manual Matches</span>
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="common_btn">

                    <a href="javascript:void(0);"> <button (click)="onSubmit()">Match Send</button></a>
                </div>
            </div>
        </div>
    </div>
    <div class="add-data-form faq-wrap">
        <div class="row">

            <div class="col-md-6">
                <div class="listingformat">
                    <div class="d-flex align-items-center justify-content-between">
                        <h3>Users</h3>
                        <div class="search-wrap">
                            <input class="form-control" placeholder="Search Customer" type="text" autocomplete="off"
                                id="shares" (keyup)="onKeyUpEvent($event)" />
                            <i class="fas fa-search"></i>
                        </div>
                    </div>
                    <div class="tabledatawrap table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>User ID</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                let item of userList
                  | paginate
                    : {
                        id: 'delivered',
                        itemsPerPage: total,
                        currentPage: p,
                        totalItems: count
                      };
                let i = index
              ">
                                    <!-- [checked]=[userId.includes(item.user_id)] -->
                                    <td>
                                        <div class="custom-checkbox">
                                            <input type="radio" value="{{item.id}}" [(ngModel)]="this.userId" id="{{item.id}}"
                                                (change)="checkItem($event,item)">
                                            <label for="{{item.id}}">
                                            </label>
                                        </div>
                                    </td>
                                    <td>#{{ item.id || "NA" }}</td>
                                    <td>{{ item.name || "NA" }}</td>
                                    <td>{{ item.email || "NA" }}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="userList?.length == 0">
                                <tr>
                                    <td colspan="12">No Data Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custompagination">
                            <pagination-controls previousLabel="Prev" nextLabel="Next" id="delivered"
                                (pageChange)="onTableDataChange($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="listingformat">
                    <div class="d-flex align-items-center justify-content-between">
                        <h3>Other Users</h3>
                        <div class="search-wrap">
                            <input class="form-control" placeholder="Search Customer" type="text" autocomplete="off"
                                id="shares" (keyup)="onKeyUpEvent($event)" />
                            <i class="fas fa-search"></i>
                        </div>
                    </div>
                    <div class="tabledatawrap table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>User ID</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                let itemm of userList
                  | paginate
                    : {
                        id: 'delivered',
                        itemsPerPage: total,
                        currentPage: p,
                        totalItems: count
                      };
                let i = index
              ">
                                    <!-- [checked]=[userId.includes(item.user_id)] -->
                                    <td>
                                        <div class="custom-checkbox">
                                            <input type="checkbox" [checked]="checkOther(itemm.id)" id="checkid + {{itemm.id}}"
                                                (change)="checkOtherItem($event,itemm)">
                                            <label for="checkid + {{itemm.id}}">
                                            </label>
                                        </div>
                                    </td>
                                    <td>#{{ itemm.id || "NA" }}</td>
                                    <td>{{ itemm.name || "NA" }}</td>
                                    <td>{{ itemm.email || "NA" }}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="userList?.length == 0">
                                <tr>
                                    <td colspan="12">No Data Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custompagination">
                            <pagination-controls previousLabel="Prev" nextLabel="Next" id="delivered"
                                (pageChange)="onTableDataChange($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Middle Content CSS End Here  -->