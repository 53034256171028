<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- FAQ HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            <span routerLink="/faq" class="breadcumbs">FAQs</span>
            <span class="navigation-arrow">></span> <span>Edit FAQ</span>
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="common_btn">
                    <a href="javascript:void(0);" routerLink="/faq"><button class="export-btn">Cancel</button></a
          >
          <a href="javascript:void(0);"
            ><button (click)="onSubmit()">Save</button></a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="add-data-form faq-wrap" [formGroup]="editfaqForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group full-input-wrap">
          <p>
            <label>Question <span>*</span></label>
            <span class="your-name"
              ><input
                name="your-name"
                value=""
                size="40"
                class="form-control"
                placeholder="Question"
                type="text"
                autocomplete="off"
                formControlName="Question"
                [ngClass]="{
                  'is-invalid': submitted && editfq['Question'].errors
                }"
              />
              <div
                class="errorDiv"
                *ngIf="submitted && editfq['Question'].errors"
                class="invalid-feedback"
              >
                <div
                  class="error"
                  *ngIf="editfq['Question'].errors['required']"
                >
                  Question is required
                </div>
              </div>
            </span>
          </p>
        </div>
        <div class="form-group full-input-wrap">
       <p>
       <label>Answer <span>*</span></label>

         <span class="your-name">
          <input
            class="form-control"
            placeholder="Answer"
            autocomplete="off"
            name=""
            id=""
            cols="30"
            rows="10"
            formControlName="Answer"
            [ngClass]="{
              'is-invalid': submitted && editfq['Answer'].errors
            }"
          />
          <div
            class="errorDiv"
            *ngIf="submitted && editfq['Answer'].errors"
            class="invalid-feedback"
          >
            <div class="error" *ngIf="editfq['Answer'].errors['required']">
              Answer is required
            </div>
          </div>
          </span>
        </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Middle Content CSS End Here  -->
