<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- Dashboard HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      Payments
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
      </div>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-md-2 pr-0">
      <select2 [data]="dataRegionList" [value]="region" minCountForSearch="0" noResultMessage="No result"
        placeholder="Select Region" (update)="updateRegion($event)">
      </select2>
    </div>
    <div class="col-md-2 pr-0">
      <select2 [data]="dataGroupList" [value]="group" minCountForSearch="0" noResultMessage="No result"
        placeholder="Select Group" (update)="updateGroup($event)">
      </select2>
    </div>
    <div class="col-md-2 pr-0">
      <select2 [data]="dataTierList" [value]="tier" minCountForSearch="0" noResultMessage="No result"
        placeholder="Select Tier" (update)="updateTier($event)">
      </select2>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
  <div class="listingformat">
    <div class="tabledatawrap table-responsive">
      <table>
        <thead>
          <tr class="trPayment">
            <th>Trx Date</th>
            <th>Trx ID</th>
            <th>User</th>
            <th>Payment Type</th>
            <th>Payment Provider</th>
            <th>Payment Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
          let item of paymentList | paginate: {id: 'payments', itemsPerPage: total, currentPage: p , totalItems: count}; let i = index;">
            <td class="width15">
              <b>{{ item.transaction_date || "NA" }}</b><br />
              <span class="subinfo">{{ item.transaction_time || "NA" }}</span>
            </td>
            <td class="width15">
              {{ item.transaction_id }}
            </td>
            <td class="width20">
              <b>{{ item.user }}</b><br />
              <span class="subinfo"># {{item.user_id || "NA" }}</span>
            </td>
            <td class="width20">
              <b>{{ item.payment_type }}</b><br />
              <span class="subinfo"># {{item.payment_detail_type || "NA" }}</span>
            </td>
            <td class="width15">
              Apple Store
            </td>
            <td class="width15">
              {{item.price}} {{item.currency}}
            </td>
            <td class="width15">
              <span class="active">VERIFIED</span>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="paymentList?.length==0">
          <tr>
            <td colspan="8">No Data Found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 custompagination">
        <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="payments"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->