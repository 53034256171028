import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-values-edit',
  templateUrl: './values-edit.component.html',
  styleUrls: ['./values-edit.component.css']
})
export class ValuesEditComponent implements OnInit {

  valuesEditForm!:FormGroup;
  submitted: any;
  token: any;
  foodData: any;
  id:any;
  valuesData: any;
  image: any;
  imageSrc: any;
  fileName!: string;


  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.valuesEditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
    this.geteditData()
  }

  get editForm() {return this.valuesEditForm.controls}

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.image = file;
      this.fileName =  file.name
      this.createImagePreview(file);
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  geteditData(){
    let obj = {
      qualification_id : this.id
    };
    this.token = localStorage['token'];
    this.prefrencesService.valuesDetail(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.valuesData = res.data;
        this.valuesEditForm.patchValue({
          'name':this.valuesData.name,
        });
        this.image = this.valuesData.image;
        this.valuesEditForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;  // Assuming image is a URL or base64 data
        }

        const imageName=this.valuesData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
        // this.toastr.success(res.message);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onSubmit(){
    this.submitted = true;
    if (this.valuesEditForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData();
    formData.append('name', this.valuesEditForm.get('name')?.value);
    formData.append('image', this.image);
    formData.append('id', this.id);
    this.prefrencesService.valuesUpdate(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/values-list']);
        this.valuesEditForm.reset();
        this.submitted = false;
      }
    });
  }

}
