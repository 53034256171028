import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-create-by-edit',
  templateUrl: './hobbie-edit.component.html',
  styleUrls: ['./hobbie-edit.component.css']
})
export class HobbieEditComponent implements OnInit {

  createeditForm!: FormGroup;
  submitted: any;
  token: any;
  id: any;
  createbyData: any;
  image: any;
  imageSrc: any;
  fileName!: string;


  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    console.log("this.id ===", this.id)
  }

  ngOnInit(): void {
    this.createeditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image: ['']
    });
    this.geteditData();
  }

  get editForm() { return this.createeditForm.controls; }

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.image = file;
      this.fileName =  file.name
      this.createImagePreview(file);
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  geteditData() {
    let obj = { hobbie_id: this.id };
    this.token = localStorage['token'];
    this.prefrencesService.createByDetails(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.createbyData = res.data;
        this.createeditForm.patchValue({ name: this.createbyData.name });
        this.image = this.createbyData.image;
        this.createeditForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;  // Assuming image is a URL or base64 data
        }
        const imageName=this.createbyData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
      } else {
        alert(res.message);
      }
    });
  }



  onSubmit() {
    this.submitted = true;
    console.log(this.createeditForm.value.image )
    this.createeditForm.value.image = this.image
    if (this.createeditForm.invalid) {
      console.log("invalid")
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData();
    formData.append('name', this.createeditForm.get('name')?.value);
    formData.append('image', this.image);
    formData.append('id', this.id);
    this.prefrencesService.createByUpdate(formData, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.createeditForm.reset();
        this.router.navigate(['/hobbies']);
        this.toastr.success(res.message)
        this.submitted = false;
      }
    });
  }
}
