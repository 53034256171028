import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(public http : HttpClient) { }
  baseUrl = environment.baseUrl

  dashUserSectionDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashUserSectionDetails`,httpOptions);
  } 

  dashApplicationDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashApplicationSectionDetails`,httpOptions);
  }  

  
  dashProfileDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashProfileSectionDetails`,httpOptions);
  }
  
  dashMemberShipDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashMembershipSectionDetails`,httpOptions);
  }

  dashMeetDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashMeetSectionDetails`,httpOptions);
  }

  dashInterestDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashInterestSectionDetails`,httpOptions);
  }

  dashHobbiesDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashHobbiesSectionDetails`,httpOptions);
  }

  dashValuesDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashIValueMostSectionDetails`,httpOptions);
  }

  dashLifeGoalDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashLifeGoalsSectionDetails`,httpOptions);
  }

  dashMaxionGoalDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashYouAreOnMaxionForDetails`,httpOptions);
  }

  dashVibeDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashMeetVibesSectionDetails`,httpOptions);
  }

  dashVibeQuestionDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashVibeCheckQuestionDetails`,httpOptions);
  }

  dashHabitsDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashHabitsSectionDetails`,httpOptions);
  }


  

  dashIamSectionDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashIAmSectionDetails`,httpOptions);
  }

  dashPreferenceDetails(token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashProfilePreferenceCount`,httpOptions);
  }

  totalUser(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashTotalUser?filter=${obj.filter}&fromdate=${obj.fromdate}&todate=${obj.todate}`,httpOptions);
  }

  totalapproveUser(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashUsers?filter=${obj.filter}&type=${obj.type}&fromdate=${obj.fromdate}&todate=${obj.todate}`,httpOptions);
  }

  totalUserProfile(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashUserProfiles?filter=${obj.filter}&type=${obj.type}&fromdate=${obj.fromdate}&todate=${obj.todate}`,httpOptions);
  }

  totaldashEvents(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `/dashEvents?type=${obj.type}`,httpOptions);
  }


}
