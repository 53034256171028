import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $ : any;
@Component({
  selector: 'app-personalities-list',
  templateUrl: './personalities-list.component.html',
  styleUrls: ['./personalities-list.component.css']
})
export class PersonalityListComponent implements OnInit {
  token: any;
  workingasList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  working_as_id: any;
  searchText: any = '';
  sideStatus: any;
  isChecked: any;
  workasId: any = [];
  masterSelected: boolean = false;
  exportList: any;


  constructor(    public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.workingasListing();
  }

  workingasListing() {
    let offset = this.p - 1;
    if(this.searchText != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.personalityList(obj,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.workingasList =  res.data.data;
        this.count = res.data.total_count
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }


  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.workingasListing()
   }

   stopSearchLoader() {
    $('.lds-ring').css('display', 'none');
  }

  searchTextFilter() {
    this.workingasListing()
  }


  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.workasId = []
    this.workingasListing();
  }

  changeStatus(event: any, item: any) {
    let obj = {
      working_as_id: item.working_as_id,
      status: item.status == 'Inactive' ? 'Active' : 'Inactive',
    };
    console.log(obj)
    this.prefrencesService
      .workingasStatus(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 'Active') {
          this.toastr.success(res.message);
        } else if (res.status == 'Inactive') {
          this.toastr.success(res.message);
        }
        this.workingasListing();
      });
  }

  openModal(item: any){
    this.working_as_id = item.id;
  }

  deletePop(){
    let obj = {
      id: this.working_as_id,
    };
    console.log(obj)
    this.prefrencesService
      .deletePersonality(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.workingasListing();
      });
  }

}
