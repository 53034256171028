import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public router : Router , private authService : AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // If the previous URL was blank, then the user is directly accessing this page
    // if (this.authService.isAuthorized()) {
    //   this.router.navigate(['/login']);
    //   return false;
    // }
    // return true;
    if (localStorage.getItem('token')) {
      // this.router.navigate(['/login']);
          return true;
      }
      this.router.navigate(['/login']);
          return false;
      }

      if(){

      }
  }


//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

//       if (this.authService.isAuthorized()) {
//         this.router.navigate(['/login']);
//         return false;
//       }
//   else{
//     return true;
//   }

//   if (localStorage.getItem('token')) {
//     // logged in so return true
//     return true;
// }
// this.router.navigate(['/login']);
//     return false;
// }



