import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-fitness-add',
  templateUrl: './fitness-add.component.html',
  styleUrls: ['./fitness-add.component.css']
})
export class FitnessAddComponent implements OnInit {
 
  createaddForm!:FormGroup;
  submitted: any;
  token: any;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.createaddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.noSpacesValidator]],
      image:['',[Validators.required]]
    });
  }


    // Custom validator to disallow spaces-only input
    noSpacesValidator(control: { value: string; }) {
      // if (control.value && ( control.value.startsWith(' ') || control.value.endsWith(' '))) {

      if (control.value && control.value.trim() === '') {
        return { 'noSpaces': true };
      }
      return null;
    }

    
  get addForm() {return this.createaddForm.controls;}

  image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
}

  onSubmit(){
    this.submitted = true;
    if (this.createaddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
   let formData = new FormData()
   formData.append('name',this.createaddForm.get('name')?.value)
   formData.append('image',this.image)
    this.prefrencesService.addFitness(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message)
        this.createaddForm.reset();
        this.router.navigate(['/fitness']);
        this.submitted = false;
      }
    });
  }

  
}
