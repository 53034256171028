import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RgtService {

  constructor(public http: HttpClient) { }
  baseUrl = environment.baseUrl

  // Services
  createRegion(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `createRegion`, obj, httpOptions);
  }

  regionList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getRegionList?limit=${obj.limit}&offset=${obj.offset}`, httpOptions);
  }

  regionGroupList(token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getRegionGroupList`, httpOptions);
  }

  getRegionDetails(id: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getRegionDetails?id=${id}`, httpOptions);
  }

  updateRegion(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.put(this.baseUrl + `updateRegion`, obj, httpOptions);
  }

  deleteRegion(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `deleteRegion`, obj, httpOptions);
  }

  createGroup(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `createGroup`, obj, httpOptions);
  }

  groupList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getGroupList?region_id=${obj.region_id}${obj.limit ? '&limit=' + obj.limit : ""}&offset=${obj.offset ? obj.offset : 0}`, httpOptions);
  }

  updateGroup(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.put(this.baseUrl + `updateGroup`, obj, httpOptions);
  }

  createTier(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `createTier`, obj, httpOptions);
  }

  tierList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getTierList?limit=${obj.limit}&offset=${obj.offset}&group_id=${obj.group_id}`, httpOptions);
  }

  updateTier(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.put(this.baseUrl + `updateTier`, obj, httpOptions);
  }
}
