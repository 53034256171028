import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-interest-add',
  templateUrl: './interest-add.component.html',
  styleUrls: ['./interest-add.component.css']
})
export class InterestAddComponent implements OnInit {
  interestaddForm!:FormGroup;
  submitted: any;
  token: any;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.interestaddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['',[Validators.required]]    })
  }


  get addForm() {return this.interestaddForm.controls;}
  image:any
  onImageSelected(event: any) {
    this.image = event.target.files[0];  
}

  onSubmit(){
    this.submitted = true;
    if (this.interestaddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
   formData.append('name',this.interestaddForm.get('name')?.value)
   formData.append('image',this.image)
    this.prefrencesService.addInterestIncome(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/interest']);
        this.interestaddForm.reset();
        this.submitted = false;
      }
    });
  }
}
