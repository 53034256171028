import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $: any;

@Component({
  selector: 'app-usually-wake-up-list',
  templateUrl: './usually-wake-up-list.component.html',
  styleUrls: ['./usually-wake-up-list.component.css']
})
export class  UsuallyWakeUpListComponent implements OnInit {
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  id: any;
  searchText: any = '';
  sideStatus: any;
  isChecked: any;
  skinToneId: any = [];
  usuallyWakeUpList:any=[]
  masterSelected: boolean = false;
  exportList: any;
  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.usuallyWakeUpListing();
  }

  usuallyWakeUpListing() {
    let offset = this.p - 1;
    if (this.searchText != '') {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.usuallyWakeUpListing(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.usuallyWakeUpList = res.data.data;
        this.count = res.data.total_count;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.usuallyWakeUpListing()
  }

 
  searchTextFilter() {
    this.usuallyWakeUpListing()
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.skinToneId = []
    this.usuallyWakeUpListing();
  }

  openModal(item: any) {
    this.id = item.id;
  }

  deletePop() {
    let obj = {
      id: this.id,
    };
    console.log(obj)
    this.prefrencesService
      .usuallyWakeUpAtDelete(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.usuallyWakeUpListing();
      });
  }


}
