import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/services/event.service';
import * as moment from 'moment';
declare var $ : any;
@Component({
  selector: 'app-sub-admin-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  p: any = 1;
  count: any = 0;
  total: any = 10;
  token: any;
  subadminData: any;
  id: any;
  search: any = '';
  isChecked: any;
  subAdminId: any = [];
  masterSelected: boolean = false;
  exportList: any;
  userObject = '';

  yourDate: Date = new Date('2023-09-19T18:14:00.000Z');
  formattedDate!: string;
  constructor( public router: Router,
    private eventService: EventService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) {
     }

  ngOnInit(): void {
    this.eventListing();
  }

 eventListing() {
  let offset = this.p - 1;
  if(this.search != '')
  {
    offset = 0;
  }
  else{
    offset = this.p - 1;
  }
    let obj = {
      limit: 10,
      offset: offset,
      search :this.search
    };
    this.token = localStorage['token'];
    this.eventService.eventList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.subadminData = res.data.data;
        this.userObject = '';
        for (let i = 0; i < this.subadminData.length; i++) {
          let element = this.subadminData[i].dateTime;
          console.log(element, 'ELEMENT');
          this.subadminData[i].dateTime = moment(element).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss');
        }

      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onKeyUpEvent(event: any){
    this.search = event.target.value;
    this.p = 1;
    this.eventListing();
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.eventListing();
    // this.ngOnInit();
  }

  changeStatus(event: any, item: any) {
    let obj = {
      id: item.id,
      status: item.status == 'Inactive' ? 'Active' : 'Inactive',
    };
    this.eventService
      .subAdminStatus(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == '200') {
          // this.toastr.success(res.message);
          this.toastr.success(res.message);
        } else if (res.status == 'Inactive') {
          this.toastr.success(res.message);
        }

        this.eventListing();
      });
  }

  openModal(item: any){
    this.id = item.id;
  }

  deletePop(){
    let obj = {
      event_id: this.id,
    };
    this.eventService
      .deleteEvent(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 400) {
          this.toastr.success(res.message);
        }
        this.eventListing();
      });
  }

  formateDate(dateTime:Date){
    const adjustedDate = new Date(dateTime);
    adjustedDate.setHours(adjustedDate.getHours() - 5);
    adjustedDate.setMinutes(adjustedDate.getMinutes() - 30);
    return formatDate(adjustedDate, 'M/d/yy, hh:mma', 'en-US'); 
   }

}
