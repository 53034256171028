import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor(public http : HttpClient) { }
  baseUrl = environment.baseUrl

  casteListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getCaste?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  casteStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editCasteStatus`,obj, httpOptions);
  }

  casteDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteCaste`,obj, httpOptions);
  }

  addCaste(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addCaste`,obj,httpOptions);
  }

  noSpacesValidator(control: { value: string; }) {
    // if (control.value && ( control.value.startsWith(' ') || control.value.endsWith(' '))) {

    if (control.value && control.value.trim() === '') {
      return { 'noSpaces': true };
    }
    return null;
  }
  
  casteDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getCasteDetails?caste_id=${obj.caste_id}`, httpOptions);
  }

  casteUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editCaste`,obj, httpOptions);
  }

  getMaxionGoals(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getMaxionGoals?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  educationStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editEducationStatus`,obj, httpOptions);
  }

  deleteMaxionGoals(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteMaxionGoals`,obj, httpOptions);
  }

  addMaxionGoals(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addMaxionGoals`,obj,httpOptions);
  }

  getMaxionGoalsDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getMaxionGoalsDetails?id=${obj.id}`,  httpOptions);
  }

  editMaxionGoals(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editMaxionGoals`,obj, httpOptions);
  }

  affluenceListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFamilyAffluence?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  exportAffluence(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportFamilyBackground`,obj, httpOptions);
  }

  affluenceStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFamilyAffluenceStatus`,obj, httpOptions);
  }

  affluenceDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteFamilyAffluence`,obj, httpOptions);
  }

  addAffluence(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addFamilyAffluence`,obj,httpOptions);
  }

  affluenceDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFamilyAffluenceDetails?family_affluence_id=${obj.family_affluence_id}`, httpOptions);
  }


  affluenceUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFamilyAffluence`,obj, httpOptions);
  }

  getLifeGoals(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getLifeGoals?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  exportReligion(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportReligionList`,obj, httpOptions);
  }



  exportFamValue(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportFamilyValueList`,obj, httpOptions);
  }

  religionCastListing(token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getReligion`,httpOptions);
  }

  religionStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editreligionStatus`,obj, httpOptions);
  }

  deleteLifeGoals(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteLifeGoals`,obj, httpOptions);
  }

  addLifeGoals(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addLifeGoals`,obj,httpOptions);
  }

  getLifeGoalsDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getLifeGoalsDetails?id=${obj.id}`, httpOptions);
  }

  editLifeGoals(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editLifeGoals`,obj, httpOptions);
  }

  familyListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFamilyType?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  familytypeStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFamilyTypeStatus`,obj, httpOptions);
  }

  familytypeDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteFamilyType`,obj, httpOptions);
  }

  addFamilytype(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addFamilyType`,obj,httpOptions);
  }

  familtypeDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFamilyTypeDetails?family_type_id=${obj.family_type_id}`, httpOptions);
  }

  familytypeUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFamilyType`,obj, httpOptions);
  }

  languageListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getNativeLanguage?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  languageStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editNativeLanguageStatus`,obj, httpOptions);
  }

  languageDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteNativeLanguage`,obj, httpOptions);
  }

  addLanguage(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addNativeLanguage`,obj,httpOptions);
  }

  languageDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getNativeLanguageDetails?native_language_id=${obj.native_language_id}`, httpOptions);
  }

  languageUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editNativeLanguage`,obj, httpOptions);
  }

  usuallyWakeUpListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getIUsuallyWakeAt?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  skintoneStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editSkinToneStatus`,obj, httpOptions);
  }

  usuallyWakeUpAtDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteIUsuallyWakeAt`,obj, httpOptions);
  }

  addIUsuallyWakeAt(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addIUsuallyWakeAt`,obj,httpOptions);
  }

  iUsuallyWakeAtDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getIUsuallyWakeAtDetails?id=${obj.id}`, httpOptions);
  }

  editIUsuallyWakeAt(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editIUsuallyWakeAt`,obj, httpOptions);
  }

  getSmokerListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getSmoker?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  bodytypeStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editBodyTypeStatus`,obj, httpOptions);
  }

  smokerDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `deleteSmoker`,obj, httpOptions);
  }

  addSmoker(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addSmoker`,obj,httpOptions);
  }

  smokerDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getSmokerDetails?id=${obj.id}`, httpOptions);
  }

  smokerUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editSmoker`,obj, httpOptions);
  }

  drinkingListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getDrinkingType?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  exportDrinking(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportDrinkPreferenceList`,obj, httpOptions);
  }

  drinkingStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editDrinkPreferenceStatus`,obj, httpOptions);
  }

  drinkingDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteDrinkPreference`,obj, httpOptions);
  }

  adddrinking(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addDrinkingType`,obj,httpOptions);
  }

  drinkingDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getDrinkingTypeDetails?drink_preference_id=${obj.drink_preference_id}`, httpOptions);
  }

  drinkingUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editDrinkPreference`,obj, httpOptions);
  }

  smokingListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getsmokingType?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  exportSmoking(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportSmokePreferenceList`,obj, httpOptions);
  }

  smokingStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editsmokePreferenceStatus`,obj, httpOptions);
  }

  smokingDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteSmokePreference`,obj, httpOptions);
  }

  addsmoking(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addsmokingType`,obj,httpOptions);
  }

  smokingDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getsmokingTypeDetails?smoking_preference_id=${obj.smoking_preference_id}`, httpOptions);
  }

  smokingUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editsmokePreference`,obj, httpOptions);
  }

  lookingListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getLookingFor?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  exportLookingFor(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportLookingForList`,obj, httpOptions);
  }

  exportCreateBy(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportCreatedByList`,obj, httpOptions);
  }

  exportGender(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportGenderList`,obj, httpOptions);
  }

  exportFatherOccupation(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportFatherOccupation`,obj, httpOptions);
  }

  exportHeight(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportHeight`,obj, httpOptions);
  }

  exportQualification(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportQualification`,obj, httpOptions);
  }

  exportWorkingWith(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportWorkingWith`,obj, httpOptions);
  }

  exportWorkingAs(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportWorkingAs`,obj, httpOptions);
  }
  exportAnnualIncome(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportAnnualIncome`,obj, httpOptions);
  }

  exportFood(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportFoodPreference`,obj, httpOptions);
  }

  exportPartnerPreference(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportPartnerPreference`,obj, httpOptions);
  }

  lookingStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editLookingForStatus`,obj, httpOptions);
  }

  lookingDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteLookingForPreference`,obj, httpOptions);
  }

  addlookingfor(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addLookingFor`,obj,httpOptions);
  }

  lookingDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getLookingForDetails?looking_for_id=${obj.looking_for_id}`, httpOptions);
  }

  lookingUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editLookingFor`,obj, httpOptions);
  }

  hobbieListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getHobbies?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  createByStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editCreatedByStatus`,obj, httpOptions);
  }

  createByDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteHobby`,obj, httpOptions);
  }

  addCreateby(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        // "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `addHobby`,obj,httpOptions);
  }

  createByDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getHobbyDetails?id=${obj.hobbie_id}`, httpOptions);
  }

  createByUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editHobby`,obj, httpOptions);
  }

  ageListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getAge?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  ageStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editAgeStatus`,obj, httpOptions);
  }

  ageDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteAgePreference`,obj, httpOptions);
  }

  addAge(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addAge`,obj,httpOptions);
  }

  ageDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getAgeDetails?age_id=${obj.age_id}`, httpOptions);
  }

  ageUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editAge`,obj, httpOptions);
  }

  fitnessListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFitness?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  genderStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editGenderStatus`,obj, httpOptions);
  }

  fitnessDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteFitness`,obj, httpOptions);
  }

  addFitness(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addFitness`,obj,httpOptions);
  }

  fitnessDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFitnessDetails?id=${obj.id}`, httpOptions);
  }

  fitnessUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editFitness`,obj, httpOptions);
  }

  chartstyleListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getChartStyle?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  chartstyleStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editChartStyleStatus`,obj, httpOptions);
  }

  chartstyleDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteChartStylePreference`,obj, httpOptions);
  }

  addChartstyle(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addChartStyle`,obj,httpOptions);
  }

  chartstyleDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getChartStyleDetails?chart_style_id=${obj.chart_style_id}`, httpOptions);
  }

  chartstyleUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editChartStyle`,obj, httpOptions);
  }

  valueListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFamilyValue?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  valueStatus(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFamilyValueStatus`,obj, httpOptions);
  }

  valueDelete(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `deleteFamilyValue`,obj, httpOptions);
  }

  addValue(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addFamilyValue`,obj,httpOptions);
  }

  valueDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFamilyValueDetails?family_value_id=${obj.family_value_id}`, httpOptions);
  }

  exportFamilyValue(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `exportFamilyValueList`,obj, httpOptions);
  }

  valueUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFamilyValue`,obj, httpOptions);
  }

  alcoholList(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getAlcoholConsumption?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  addAlcoholConsumption(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addAlcoholConsumption`,obj,httpOptions);
  }

  alcoholCunsumptionDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getAlcoholConsumptionDetails?id=${obj.height_id}`, httpOptions);
  }

  editAlocoholConsumption(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
      })
    }
    return this.http.post(this.baseUrl + `editAlcoholConsumption`,obj, httpOptions);
  }

  occupationListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFatherOccupation?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  addoccupation(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addFatherOccupationPreference`,obj,httpOptions);
  }

  occupationDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getFatherOccupationDetails?father_occupation_id=${obj.father_occupation_id}`, httpOptions);
  }

  occupationUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editFatherOccupation`,obj, httpOptions);
  }

  motheroccupationListing(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getMotherOccupation?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  addmotheroccupation(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addMotherOccupationPreference`,obj,httpOptions);
  }

  motheroccupationDetails(obj:any , token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getMotherOccupationDetails?mother_occupation_id=${obj.mother_occupation_id}`, httpOptions);
  }

  motheroccupationUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token,
        "Content-Type":"application/json"
      })
    }
    return this.http.post(this.baseUrl + `editMotherOccupation`,obj, httpOptions);
  }

  partnerpreList(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getPartnerPreference?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  addpartnerpreferences(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addPartnerPreference`,obj,httpOptions);
  }

  partnerDtls(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getPartnerPreferenceDetails?partner_preference_id=${obj.partner_preference_id}`,httpOptions);
  }

  partnerDtlsUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `editPartnerPreference`,obj,httpOptions);
  }
  getMeetVibes(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getMeetVibes?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  addMeetVibes(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addMeetVibes`,obj,httpOptions);
  }

  getMeetVibesDetails(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getMeetVibesDetails?id=${obj.food_preference_id}`,httpOptions);
  }

  editMeetVibes(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `editMeetVibes`,obj,httpOptions);
  }


  workingWithList(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getWorkingWith?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  workingWithPreferences(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `addWorkingWithPreference`,obj,httpOptions);
  }

  workingWithDtls(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getWorkingWithDetails?working_id=${obj.working_id}`,httpOptions);
  }

  workingWithUpdate(obj:any,token:any){
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.post(this.baseUrl + `editWorkingWith`,obj,httpOptions);
  }

  personalityList(obj:any,token:any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken":token
      })
    }
    return this.http.get(this.baseUrl + `getPersonality?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
  }

  personalityAdd(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.post(this.baseUrl + `addPersonality`,obj,httpOptions);
}

personalityDtls(obj:any,token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `getPersonalityDetails?id=${obj.working_as_id}`,httpOptions);
}

editPersonality(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.post(this.baseUrl + `editPersonality`,obj,httpOptions);
}



countryList(obj:any,token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `countryList?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
}

stateList(obj:any,token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `stateList?limit=${obj.limit}&offset=${obj.offset}&country_id=${obj.country_id}&search=${obj.search}`,httpOptions);
}

cityList(obj:any,token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `cityList?limit=${obj.limit}&offset=${obj.offset}&state_id=${obj.state_id}&search=${obj.search}`,httpOptions);
}

interestList(obj:any,token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `getInterest?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
}

addInterestIncome(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.post(this.baseUrl + `addInterest`,obj,httpOptions);
}

interestDetail(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `getInterestDetails?id=${obj.id}`,httpOptions);
}

interestUpdate(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.post(this.baseUrl + `editInterest`,obj,httpOptions);
}

parentLangList(
  token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `getParentLanguage`,httpOptions);
}

valuesList(obj:any,token:any) {
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `getValues?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,httpOptions);
}

addValues(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.post(this.baseUrl + `addValues`,obj,httpOptions);
}

valuesDetail(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.get(this.baseUrl + `getValuesDetails?id=${obj.qualification_id}`,httpOptions);
}

valuesUpdate(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token
    })
  }
  return this.http.post(this.baseUrl + `editValues`,obj,httpOptions);
}

qualificationStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editQualificationPreferenceStatus`,obj, httpOptions);
}

fatherocuStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editFatherOccupationStatus`,obj, httpOptions);
}

motherocuStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editMotherOccupationStatus`,obj, httpOptions);
}

workingwithStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editWorkingWithStatus`,obj, httpOptions);
}

workingasStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editWorkingAsStatus`,obj, httpOptions);
}

foodpreStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editFoodPreferenceStatus`,obj, httpOptions);
}

parnterStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editPartnerPreferenceStatus`,obj, httpOptions);
}

incomeStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editAnnualIncomeStatus`,obj, httpOptions);
}

heightStatus(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `editHeightPreferenceStatus`,obj, httpOptions);
}

incomeDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deleteInterest`,obj, httpOptions);
}

partnerDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deletePartnerPreference`,obj, httpOptions);
}

deleteMeetVibes(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deleteMeetVibes`,obj, httpOptions);
}

deletePersonality(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deletePersonality`,obj, httpOptions);
}

workingwithDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deleteWorkingWith`,obj, httpOptions);
}

fatherocuDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deleteFatherOccupation`,obj, httpOptions);
}

motherocuDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deleteMotherOccupation`,obj, httpOptions);
}

valuesDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `deleteValues`,obj, httpOptions);
}

alcoholConsumptionDelete(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
    })
  }
  return this.http.post(this.baseUrl + `deleteAlcoholConsumption`,obj, httpOptions);
}

exportCaste(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `exportCasteList`,obj, httpOptions);
}

exportBodyType(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `exportBodyTypeList`,obj, httpOptions);
}

exportCourse(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `exportEducationList`,obj, httpOptions);
}

exportFamilyType(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `exportFamilyTypeList`,obj, httpOptions);
}

exportNativeLang(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `exportNativelanguageList`,obj, httpOptions);
}

exportSkintone(obj:any,token:any){
  var httpOptions;
  httpOptions = {
    headers: new HttpHeaders({
      "accessToken":token,
      "Content-Type":"application/json"
    })
  }
  return this.http.post(this.baseUrl + `exportSkinToneList`,obj, httpOptions);
}


}
