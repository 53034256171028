import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
import { EventService } from 'src/app/services/event.service';
declare var $: any;
@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css'],
})
export class EventEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  token: any;
  roleList: any;
  editsubadminForm!: FormGroup;
  submitted: any;
  menuArr: any = [];
  menuErr = true;
  menuArr1: any = [];
  id: any;
  subadminData: any;
  roleData: any;
  getRoleVal : any = [];
  image: any;
  imageSrc: any;
  fileName!: string;
  blankImg: any = false;
  
  constructor(
    public router: Router,
    private eventService: EventService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    private activateroute: ActivatedRoute,
    private datePipe: DatePipe,
    private prefrencesService:PreferencesService 
  ) {}

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.geteditData();
    this.editsubadminForm = this.formBuilder.group({
      title: ['', [Validators.required,this.prefrencesService.noSpacesValidator]],
      location: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      dateTime: ['',[ Validators.required]],
      url: ['', [Validators.required,Validators.pattern(/^(https?:\/\/)?([\w.]+\.\w{2,})(\/.*)?$/i)]],
      description:['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image: [''],
    });
  }

  // it will be trigger when image uploaded
  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (this.calculateAspectRatio(width, height) !== 5 / 3) {
                alert('Please upload an image with a 5:3 aspect ratio.');
                this.resetFileInput();
              } 
              else{
                this.image = file;
                this.fileName =  file.name
                this.createImagePreview(file);
              }
            };
    }
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }
  
  // it returns form control
  get editSub() {
    return this.editsubadminForm.controls;
  }

  geteditData() {
    let obj = {
      id: this.id,
    };
    this.token = localStorage['token'];
    this.eventService.adminDetail(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.subadminData = res.data;
        const adjustedDate = new Date(this.subadminData.dateTime);
        adjustedDate.setHours(adjustedDate.getHours() - 5);
        adjustedDate.setMinutes(adjustedDate.getMinutes() - 30);
        this.editsubadminForm.patchValue({
          title: this.subadminData.title,
          location: this.subadminData.location,
          dateTime:this.datePipe.transform(adjustedDate, 'yyyy-MM-ddTHH:mm:ss'),
          url: this.subadminData.url,
          description: this.subadminData.description
        });
        const imageName=this.subadminData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
        this.image = this.subadminData.image;
        this.editsubadminForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;
          console.log(this.imageSrc )
        }
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.editsubadminForm.invalid) {
      this.blankImg == true;
      return;
    }
    if(this.image === ''){
      alert('Please Upload Image')
    }
    this.token = localStorage['token'];
    var formData = new FormData ();
    formData.append('event_id', this.id );
    formData.append('title', this.editsubadminForm.get('title')?.value);
    formData.append('location', this.editsubadminForm.get('location')?.value);
    formData.append('dateTime', this.editsubadminForm.get('dateTime')?.value);
    formData.append('url',this.editsubadminForm.get('url')?.value );
    formData.append('description',this.editsubadminForm.get('description')?.value );
    formData.append('image',this.image );
    this.eventService.eventUpdate(formData, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/event-list']);
        this.editsubadminForm.reset();
        this.submitted = false;
      }
    });
  }

  resetFileInput() {
    // Reset the file input to clear the selected file
    this.fileInput!.nativeElement.value = '';
  }

  calculateAspectRatio(width: number, height: number): number {
    return width / height;
  }
  
}
