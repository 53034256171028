import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(public http: HttpClient) { }
  baseUrl = environment.baseUrl

  customerPendingList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getApprovalPendingList?limit=${obj.limit}&offset=${obj.offset}&fromdate=${obj.fromdate}&todate=${obj.todate}&search=${obj.search}`, httpOptions);
  }

  customerFeedback(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getFeedbackReceivedUserList?limit=${obj.limit}&offset=${obj.offset}&id=${obj.id}`, httpOptions);
  }

  approvedUnapproved(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `approveUser`, obj, httpOptions);
  }

  blockUnblock(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `adminActionOnApprovedUser`, obj, httpOptions);
  }


  customerList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getApprovedUserList?limit=${obj.limit}&offset=${obj.offset}&fromdate=${obj.fromdate}&todate=${obj.todate}&search=${obj.search}`, httpOptions);
  }

  casteReligion(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getReligionCaste?religion_id=${obj.religion_id}`, httpOptions);
  }

  customerPhone(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `/checkMobile`, obj, httpOptions);
  }

  trialPacked(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `/assignTrialPlan`, obj, httpOptions);
  }



  customerEmail(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `/checkEmail`, obj, httpOptions);
  }

  emailCheck(obj: any) {
    return this.http.get(`https://api.neverbounce.com/v4/single/check?key=private_d3dba642e4f1469d9a773b98dfc41b89&email=${obj.email}`);
  }

  customerSearch(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `/keySearch`, obj, httpOptions);
  }

  unApprovedCustomerDetail(id: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `viewApprovalPendingDetails?id=${id}`, httpOptions);
  }

  approvedCustomerDetail(id: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `viewApprovedUserDetails?id=${id}`, httpOptions);
  }

  customerMeetList(id: any, obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `getUserMatchList?id=${id}&type=${obj.type}`, httpOptions);
  }

  customerStatus(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
        "Content-Type": "application/json"
      })
    }
    return this.http.post(this.baseUrl + `/updateUserStatus`, obj, httpOptions);
  }

  getPreferences(token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getPreferenceCategories`, httpOptions);
  }

  getCreatelist(token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getCreatedBy`, httpOptions);
  }

  getEducationlist(token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getEducationDetails`, httpOptions);
  }

  getLanuageList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getNativeLanguage?language_id=${obj.language_id}`, httpOptions);
  }

  parentLangList(token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getParentLanguage`, httpOptions);
  }

  customerAdd(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
        "Content-Type": "application/json"
      })
    }
    return this.http.post(this.baseUrl + `/addUser`, obj, httpOptions);
  }

  importCustomer(formData: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `/importUser`, formData, httpOptions);
  }

  hideCustomer(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `/hideUnhideUserProfile`, obj, httpOptions);
  }

  exportCustomer(obj: any) {
    // var httpOptions;
    // httpOptions = {
    //   headers: new HttpHeaders({
    //     "accessToken":token,
    //     responseType: 'blob' as 'json'
    //   })
    // }
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(this.baseUrl + `/exportSubscribedCustomerList?search=${obj.search}&user_type=${obj.user_type}`, httpOptions);
  }

  userDelete(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `/deleteCustomer`, obj, httpOptions);
  }

  userStatus(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `/verifyUnverifyUserProfile`, obj, httpOptions);
  }

  associateTier(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `/associateTier`, obj, httpOptions);
  }

  courseList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `/getQualificationEducation?qualification_id=${obj.qualification_id}`, httpOptions);
  }

}
