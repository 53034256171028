import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $ : any;

@Component({
  selector: 'app-fitness-list',
  templateUrl: './fitness-list.component.html',
  styleUrls: ['./fitness-list.component.css']
})
export class FitnessListComponent implements OnInit {
  token: any;
  genderList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  fitness_id: any;
  searchText: any = '';
  sideStatus: any;
  isChecked: any;
  genderId: any = [];
  masterSelected: boolean = false;
  exportList: any;

  constructor(    public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.fitnessListing();
  }

  fitnessListing() {
    let offset = this.p - 1;
    if(this.searchText != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.fitnessListing(obj,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.genderList =  res.data.data;
        this.count = res.data.total_count;
        console.log(this.genderList);
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  checkUncheckAll(event: any) {
    let checkAll = event.target.checked;
    this.masterSelected = checkAll;
    if (this.masterSelected) {
      this.genderList.forEach((element: any) => {
        this.genderId.push(element.gender_id);
      });
    } else {
      this.genderId = [];
    }
  }

  checkMenu(event: any, gender_id: any) {
    this.isChecked = event.target.checked;
    if (this.isChecked) {
      if (this.genderId.indexOf(gender_id) == -1) {
        this.genderId.push(gender_id);
      }
    } else {
      let index = this.genderId.indexOf(gender_id);
      this.genderId.splice(index, 1);
    }
    console.log(this.genderId);
  }

  onExport() {
    if (confirm('Are you sure you want to export these Gender?')) {
      let obj = {
        ids: this.genderId.join(','),
        search :this.searchText
      };
      this.token = localStorage['token'];
      this.prefrencesService
        .exportGender(obj, this.token)
        .subscribe((res: any) => {
          if (res && res.status == 200) {
            this.ngOnInit();
            this.exportList = res.data;
            window.open(this.exportList,"_self");
          } else {
            // this.toastr.success(res.message);
          }
        });
    }
    // if (this.genderId.length == 0) {
    //   this.toastr.error('Please Select Gender');
    // } else {
    //   if (confirm('Are you sure you want to export these Gender?')) {
    //     let obj = {
    //       ids: this.genderId.join(','),
    //       search :this.searchText
    //     };
    //     this.token = localStorage['token'];
    //     this.prefrencesService
    //       .exportGender(obj, this.token)
    //       .subscribe((res: any) => {
    //         if (res && res.status == 200) {
    //           this.ngOnInit();
    //           this.exportList = res.data;
    //           window.open(this.exportList,"_self");
    //         } else {
    //           this.toastr.success(res.message);
    //         }
    //       });
    //   }
    // }
  }

  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.fitnessListing()
   }

   stopSearchLoader() {
    $('.lds-ring').css('display', 'none');
  }

  searchTextFilter() {
    this.fitnessListing()
  }

  changeStatus(event: any, item: any) {
    let obj = {
      gender_id: item.gender_id,
      status: item.status == 'Inactive' ? 'Active' : 'Inactive',
    };
    console.log(obj)
    this.prefrencesService
      .genderStatus(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 'Active') {
          // this.toastr.success(res.message);
        } else if (res.status == 'Inactive') {
          // this.toastr.success(res.message);
        }
        this.fitnessListing();
      });
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.genderId = []
    this.fitnessListing();
  }

  openModal(item: any){
    this.fitness_id = item.id;
  }

  deletePop(){
    let obj = {
      id: this.fitness_id,
    };
    console.log(obj)
    this.prefrencesService
      .fitnessDelete(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.fitnessListing();
      });
  }

  works(event:any){
    event.sideName.menuList.forEach((element:any) => {
      if(element.role_name=='Master Data')
      {
        this.sideStatus=element.role_status;
      }
    });
  }


}
