<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- FAQ HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            <span>Static Content</span> <span class="navigation-arrow">></span>
            <span>Privacy Policy</span>
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="common_btn">
                    <!-- <a href="javascript:void(0);"><button class="export-btn" (click)="cancelBtn()">Cancel</button></a -->
                    <a href="javascript:void(0);"> <button (click)="onSubmit()"
                            [disabled]="this.sideStatus == 'Read'">Save</button></a>
                </div>
            </div>
        </div>
    </div>
    <div class="staticcontent-wrap">
        <div class="content-main-div">
            <ckeditor (change)="onChange($event)" formControlName="Description"></ckeditor>
            <!-- app.component.html -->
            <!-- <ckeditor [editor]="Editor" data="<p>{{this.content?.content}}</p>" (change)="onChange($event)" ></ckeditor> -->
            <ckeditor [config]="config" [editor]="Editor" data="<p>{{this.content?.content}}</p>"
                (change)="onChange($event)"></ckeditor>


            <!-- <ckeditor data="<p>{{this.content?.privacy_policy}}</p>" (change)="onChange($event)" [readOnly]="this.sideStatus == 'Read'"></ckeditor> -->
        </div>
    </div>
</div>
<!-- Middle Content CSS End Here  -->