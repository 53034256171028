<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- Dashboard HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      Regions
    </h2>
    <div class="addbtnhead">
      <div class="page-header-right">
        <div class="common_btn">
          <button class="add-user-btn" data-toggle="modal" data-target="#upsertRegionModal" (click)="openModal()">
            <img src="assets/images/plus.svg" alt=""> Add Region
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
  <div class="listingformat">
    <div class="tabledatawrap table-responsive">
      <table>
        <thead>
          <tr class="trRegion">
            <th>Region Name</th>
            <th>No Groups</th>
            <th>No Tiers</th>
            <th>No Members</th>
            <th>Region Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
          let item of regionList | paginate: {id: 'regions', itemsPerPage: total, currentPage: p , totalItems: count}; let i = index;">
            <td>{{ item.name || "NA" }}</td>
            <td class="width15">{{ item.count_group || "NA" }}</td>
            <td class="width15">{{ item.count_tier || "NA" }}</td>
            <td class="width15">{{ item.count_customer || "NA" }}</td>
            <td><span [class]="item?.status.toLowerCase()">{{ item.status || "NA" }}</span></td>
            <td class="width20">
              <div class="action-btns hide-profile-btn">
                <button [routerLink]="['/groups-list', item.id]">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
                <button (click)="openModal(item.id, true)" data-toggle="modal" data-target="#upsertRegionModal"
                  data-placement="top" title="Edit">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                </button>
                <button (click)="openModal(item.id)" data-toggle="modal" data-target="#deleteRegionModal"
                  data-placement="top" title="Delete">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="regionList?.length==0">
          <tr>
            <td colspan="6">No Data Found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 custompagination">
        <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="regions"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!-- Add Region Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="upsertRegionModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          {{ editing ? "Edit" : "Add"}} Region
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <form>
            <div class="faq-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Region Name <span>*</span></label>
                      <span>
                        <input name="name" [(ngModel)]="regionName" size="40" class="form-control" aria-required="true"
                          aria-invalid="false" type="text" autocomplete="off" placeholder="Name">
                        <div class="errorDiv" *ngIf="submitted && !regionName" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Country <span>*</span></label>
                      <span>
                        <select2 [data]="dataCountryList" [value]="regionCountry" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateCountry($event)">
                        </select2>
                        <div class="errorDiv" *ngIf="submitted && !regionCountry" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Status</label>
                      <span>
                        <select2 [data]="dataStatusList" [value]="regionStatus" minCountForSearch="0"
                          noResultMessage="No result" (update)="updateStatus($event)">
                        </select2>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="common_btn">
              <button data-dismiss="modal">Back</button>
              <button id="close" (click)="onSubmit()">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Region Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="deleteRegionModal" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Delete
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <p> Are you sure you want to delete this region?</p>
          <div class="common_btn">
            <button data-dismiss="modal">Cancel</button>
            <button (click)="onDelete()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>