import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-fitness-edit',
  templateUrl: './fitness-edit.component.html',
  styleUrls: ['./fitness-edit.component.css'],
})
export class FitnessEditComponent implements OnInit {
  fitnessEditForm!:FormGroup;
  submitted: any;
  token: any;
  id:any;
  fitnessData: any;
  imageSrc: any;
  fileName!: string;


  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { 
      this.id = this.activateroute.snapshot.paramMap.get('id');
      console.log("this.id ===",this.id )
    }

  ngOnInit(): void {
    this.fitnessEditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
    this.geteditData();
  }

  get editForm() {return this.fitnessEditForm.controls;}

  geteditData(){
    let obj = {
      id : this.id
    };
    this.token = localStorage['token'];
    this.prefrencesService.fitnessDetails(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.fitnessData = res.data;
        this.fitnessEditForm.patchValue({
          name:this.fitnessData.name,
        });
        this.image = this.fitnessData.image;
        this.fitnessEditForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;  // Assuming image is a URL or base64 data
        }
        const imageName=this.fitnessData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
        // this.toastr.success(res.message);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  image:any
  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.image = file;
      this.fileName =  file.name

      this.createImagePreview(file);
    }
}
createImagePreview(file: File) {
  const reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageSrc = event.target.result;
  };
  reader.readAsDataURL(file);
}
  onSubmit(){
    this.submitted = true;
    if (this.fitnessEditForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
     formData.append('name',this.fitnessEditForm.get('name')?.value)
     formData.append('image',this.image)
     formData.append('id',this.id)
    this.prefrencesService.fitnessUpdate(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.fitnessEditForm.reset();
        this.router.navigate(['/fitness']);
        this.submitted = false;
      }
    });
  }

}
