import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-usually-wake-up-edit',
  templateUrl: './usually-wake-up-edit.component.html',
  styleUrls: ['./usually-wake-up-edit.component.css']
})
export class UsuallyWakeUpEditComponent implements OnInit {
  usuallyWakeUpEditForm!:FormGroup;
  submitted: any;
  token: any;
  id:any;
  wakeUpData: any;
  image: any;
  imageSrc: any;
  fileName!: string;

  
  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.usuallyWakeUpEditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
    this.geteditData();
  }


  get editForm() {return this.usuallyWakeUpEditForm.controls;}

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.image = file;
      this.fileName =  file.name
      this.createImagePreview(file);
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  geteditData() {
    let obj = {
      id: this.id,
    };
    this.token = localStorage['token'];
    this.prefrencesService
      .iUsuallyWakeAtDetails(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.wakeUpData = res.data;
        this.usuallyWakeUpEditForm.patchValue({
          name:this.wakeUpData.name,
        });
        const imageName=this.wakeUpData.image.split('/')
        this.fileName=imageName[imageName?.length - 1]
        this.image = this.wakeUpData.image;
        this.usuallyWakeUpEditForm.value.image = this.image;
        if (this.image) {
          this.imageSrc = this.image;
          console.log(this.imageSrc )
        }
          // this.toastr.success(res.message);
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  onSubmit(){
    this.submitted = true;
    if (this.usuallyWakeUpEditForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.usuallyWakeUpEditForm.get('name')?.value)
    formData.append('image',this.image)
    formData.append('id',this.id)
    this.prefrencesService.editIUsuallyWakeAt(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.usuallyWakeUpEditForm.reset();
        this.router.navigate(['/wake-up-list']);
        this.submitted = false;
      }
    });
  }

}
