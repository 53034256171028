import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReferralService } from 'src/app/services/referral.service';

@Component({
  selector: 'app-referral-management',
  templateUrl: './referral-management.component.html',
  styleUrls: ['./referral-management.component.css']
})
export class ReferralManagementComponent {
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  status: any;
  id: any = '';
  referList: any;
  search: string = '';
  chat_id: any;
  faq_id: any;
  notexist: any;
  err: any;
  sideStatus: any;
  exportList:any;
  blob:any;

  constructor(
    public router: Router,
    private referralService: ReferralService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.referListing();
  }

  
  onKeyUpEvent(event: any) {
    this.search = event.target.value;
    this.referListing()
  }

  referListing() {
    let offset = this.p - 1;
    if(this.search != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search:this.search
    };
    this.token = localStorage['token'];
    this.referralService.refferList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.referList = res.data.userData;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        // this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.notexist = res.message;
        this.err = res.data
      }
      else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.referListing();
  }

  onExport() {
    if (confirm('Are you sure you want to export these Referral Data?')) {
      let obj = {
        search: this.search
      };
      this.token = localStorage['token'];
      this.referralService
        .exportrefer(this.token).subscribe((res: any) => {
          debugger
          this.blob =  window.URL.createObjectURL(new Blob([res], {type: 'application/xlsx'}));
          console.log(this.blob , 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa')
          var downloadURL = window.URL.createObjectURL(res);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.setAttribute('download', "Export Report.xlsx");
          // link.download = "help.pdf";
          link.click();
          // if (res.status == 200) {
          //   this.exportList = res;
          //   window.open(this.exportList, "_self");
          //   this.toastr.success(res.message);
          // } else if (res.status == 201) {
          //   this.toastr.success(res.message);
          // }
        })
    }
  }





}
