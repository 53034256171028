<!--Login Section Start Here-->
<section>
    <div class="login-wrap-saga">
        <div class="row">
            <div class="col-md-6 d-flex justify-content-center">
                <div class="login-card-wrap">
                    <div class="mobile-login ">
                        <img src="assets/images/Maxion-Logo.png" alt="" />
                        <p>Login to Your account</p>
                    </div>
                    <div class="login-card" [formGroup]="loginForm">
                        <h2>Login</h2>
                        <p>Enter your email and password to login to your account.</p>
                        <div class="common_input_warp">
                            <div class="form-group full-input-wrap">
                                <p>
                                    <!-- <label class="">Email Address</label> -->
                                    <span class="your-name">
                                        <input type="email" class="form-control" placeholder="Email Address"
                                            formControlName="Email" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': submitted && loginf['Email'].errors }">
                                        <div class="errorDiv" *ngIf="submitted && loginf['Email'].errors"
                                            class="invalid-feedback">
                                            <div class="error" *ngIf="loginf['Email'].errors['required']">Email is
                                                required</div>
                                            <div class="error" *ngIf="loginf['Email'].hasError('pattern')">Invalid Email
                                            </div>
                                        </div>
                                    </span>
                                </p>
                            </div>
                            <div class="form-group full-input-wrap passwordfield">
                                <p>
                                    <!-- <label class="">Password</label> -->
                                    <span class="your-name">
                                        <input name="your-name" placeholder="Password"
                                            class="form-control passwordinput" type="password"
                                            formControlName="Password" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': submitted && loginf['Password'].errors }"
                                            minlength="6" maxlength="50" />
                                        <div class="errorDiv" *ngIf="submitted && loginf['Password'].errors"
                                            class="invalid-feedback">
                                            <div class="error" *ngIf="loginf['Password'].errors['required']">Password is
                                                required</div>

                                            <div class="error" *ngIf="loginf['Password'].errors['minlength']">
                                                Password must be at least 6 characters long.
                                            </div>
                                            <div class="error" *ngIf="loginf['Password'].errors['maxlength']">
                                                Password cannot exceed 16 characters.
                                            </div>
                                        </div>
                                    </span>
                                </p>
                                <span class="toggle-password">
                                    <i class="fa fa-eye-slash"></i>
                                </span>

                            </div>

                        </div>
                        <div class="common_btn">
                            <button (click)="onSubmit()">Login</button>
                        </div>
                    </div>
                    <div class="shadow3bck"></div>
                </div>
            </div>
            <div class="col-md-6" style="position: relative;
            bottom: 73px;">
                <div class="login-content">
                    <img src="assets/images/Maxion-Logo.png" alt="" />
                    <p class="login-text">
                        Login to Your <br /> account
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Section End Here-->