import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-create-by-add',
  templateUrl: './hobbie-add.component.html',
  styleUrls: ['./hobbie-add.component.css']
})
export class HobbieAddComponent implements OnInit {
  createaddForm!:FormGroup;
  submitted: any;
  token: any;

  constructor(public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder : FormBuilder
    ) { }

  ngOnInit(): void {
    this.createaddForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['',[Validators.required]]
    });

  }


  get addForm() {return this.createaddForm.controls;}
  
  image:any
  uploadFile(e:any){
    this.image=e.target.files[0]
    this.createaddForm.value.image = this.image
  }

  onSubmit(){
    this.submitted = true;
    if (this.createaddForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
   let formData = new FormData()
   formData.append('name',this.createaddForm.get('name')?.value)
   formData.append('image',this.image)

    // let obj = {
    //   name:this.createaddForm.get('CreateBy')?.value,
    //   image:this.createaddForm.get('image')?.value || ''
    // }
    this.prefrencesService.addCreateby(formData,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.createaddForm.reset();
        this.toastr.success(res.message)
        this.router.navigate(['/hobbies']);
        this.submitted = false;
      }
    });
  }


}
