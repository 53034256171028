import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RgtService } from 'src/app/services/rgt.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  token: any;
  paymentList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  err: any;
  limit: any = 10;
  offset: any;
  regionGroupList: any;
  groupTierList: any;
  dataRegionList: Select2Data = [];
  dataGroupList: Select2Data = [];
  dataTierList: Select2Data = [];
  region: any = "";
  group: any = "";
  tier: any = "";

  constructor(
    public router: Router,
    private rgtService: RgtService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.paymentListing()
    this.regionListing()
  }

  paymentListing() {
    this.offset = this.p - 1;
    let obj = {
      limit: this.total,
      offset: this.offset,
      region_id: this.region,
      group_id: this.group,
      tier_id: this.tier
    };
    this.token = localStorage['token'];

    this.paymentService.paymentList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;

        res.data.data = res.data.data.map((item: any) => {
          let paymentType;
          let paymentDetailType;

          switch (item.product_id) {
            case 'maxplan3':
              paymentType = 'Subscription'
              paymentDetailType = 'Monthly'
              break;
            case 'maxplan2':
              paymentType = 'Subscription'
              paymentDetailType = 'Yearly'
              break;
            case 'maxplan1':
              paymentType = 'Subscription'
              paymentDetailType = '3 Monthly'
              break;
            case 'paytogetontop':
              paymentType = 'One-off'
              paymentDetailType = 'Pay to get on top'
              break;
          }

          return {
            ...item,
            transaction_date: moment(item.transaction_date).tz('Asia/Dubai').format('MM/DD/YYYY'),
            transaction_time: moment(item.transaction_date).tz('Asia/Dubai').format('hh:mm A'),
            payment_type: paymentType,
            payment_detail_type: paymentDetailType
          }
        })

        this.paymentList = res.data.data;
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  regionListing() {
    this.token = localStorage['token'];

    this.rgtService.regionGroupList(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.regionGroupList = res.data.data;

        let regionList: any = [];
        res.data.data.forEach((regionItem: any) => {
          regionList.push({
            value: regionItem.id,
            label: regionItem.name
          })
        });

        this.dataRegionList = [{
          "label": "",
          "options": regionList
        }]
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  groupListing() {
    let obj = {
      region_id: this.region,
    };

    this.token = localStorage['token'];

    this.rgtService.groupList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.groupTierList = res.data.data;
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.paymentListing();
  }

  updateRegion(event: Select2UpdateEvent) {
    this.region = event.value;
    this.group = "";
    this.tier = "";

    if (this.regionGroupList) {
      const currentRegion = this.regionGroupList.find((item: any) => {
        return item.id == event.value
      })

      let groupList = currentRegion.groups ?? [];

      groupList = groupList.map((item: any) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      this.dataGroupList = [{
        "label": "",
        "options": groupList
      }]

      this.groupListing()
      this.paymentListing()
    }
  }

  updateGroup(event: Select2UpdateEvent) {
    this.group = event.value;
    this.tier = "";

    if (this.groupTierList) {
      const currentGroup = this.groupTierList.find((item: any) => {
        return item.id == event.value
      })

      let tiersList = currentGroup.tiers ?? [];

      tiersList = tiersList.map((item: any) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      this.dataTierList = [{
        "label": "",
        "options": tiersList
      }]

      this.paymentListing()
    }
  }

  updateTier(event: Select2UpdateEvent) {
    this.tier = event.value;

    if (this.groupTierList) {
      this.paymentListing()
    }
  }
}
