import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.css']
})
export class CustomerFeedbackComponent {
  id : any = Number;
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  listData:any;
  notexist:any;
  err:any;
  constructor(
    public router: Router,
    private customerService: CustomerService,
    private toastr: ToastrService,
    public activateroute :ActivatedRoute
  ) {
    this.id = this.activateroute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.feedbackList();
  }

  feedbackList(){
    let offset = this.p - 1;
   
    let obj = {
      limit: 10,
      offset: offset,
      id: this.id
    };
    this.token = localStorage['token'];
    this.customerService.customerFeedback(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.listData = res.data.data;
        console.log(this.listData , 'List')
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        // this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.notexist = res.message;
        this.err = res.data
      }
      else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }
}
