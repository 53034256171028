import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePassword!: FormGroup;
  submitted = false;
  token: any;

  constructor(public router : Router , private setingService : SettingService, private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activateroute: ActivatedRoute) { }
  ngOnInit(): void {
    this.changePassword = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
  }

  get addForm() {
    return this.changePassword.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePassword.invalid) {
      return;
    }
    if(this.changePassword.get('oldPassword')?.value == this.changePassword.get('newPassword')?.value)
    {

      this.toastr.error('Old and new password should not be same.');
      return;
    }
    this.token = localStorage['token'];
    let obj = {
      oldPassword: this.changePassword.get('oldPassword')?.value,
      newPassword: this.changePassword.get('newPassword')?.value,
    };
    this.setingService.changePassword(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.changePassword.reset();
        this.submitted = false;
        this.router.navigate(['/login']);
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else {
        this.toastr.error(res.message);
        // alert(res.message);
      }
    });
  }

  showPassword='password'
  showNewPassword(){
   (this.showPassword=='password')? (this.showPassword='text'):(this.showPassword='password')
  }

  showOldPass='password'
  showOldPassword(){
    (this.showOldPass=='password')? (this.showOldPass='text'):(this.showOldPass='password')
   }
}
