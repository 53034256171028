import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-life-goals-edit',
  templateUrl: './life-goals-edit.component.html',
  styleUrls: ['./life-goals-edit.component.css'],
})
export class LifeGoalsEditComponent implements OnInit {
  lifeGoalsEditForm!: FormGroup;
  submitted: any;
  token: any;
  id: any;
  lifeGoalsData: any;
  imageSrc: any;
  fileName!: string;

  constructor(
    public router: Router,
    private prefrencesService: PreferencesService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.lifeGoalsEditForm = this.formBuilder.group({
      name: ['',[Validators.required,this.prefrencesService.noSpacesValidator]],
      image:['']
    });
    this.geteditData();
  }

  get editForm() {
    return this.lifeGoalsEditForm.controls;
  }

  image:any
onImageSelected(event: any) {
  const file = event.target.files[0];
  if (file) {
    this.image = file;
    this.fileName =  file.name
    this.createImagePreview(file);
  }
}

createImagePreview(file: File) {
  const reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageSrc = event.target.result;
  };
  reader.readAsDataURL(file);
}
  geteditData() {
    let obj = {
      id: this.id,
    };
    this.token = localStorage['token'];
    this.prefrencesService
      .getLifeGoalsDetails(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          debugger
          this.lifeGoalsData = res.data;
          this.lifeGoalsEditForm.patchValue({
            'name':this.lifeGoalsData.name,
          });
          const imageName=this.lifeGoalsData.image.split('/')
          this.fileName=imageName[imageName?.length - 1]
          this.image = this.lifeGoalsData.image;
          this.lifeGoalsEditForm.value.image = this.image;
          if (this.image) {
            this.imageSrc = this.image;
          }
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.lifeGoalsEditForm.invalid) {
      return;
    }
    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('name',this.lifeGoalsEditForm.get('name')?.value)
    formData.append('image',this.image)
    formData.append('id',this.id)
    this.prefrencesService
      .editLifeGoals(formData, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message);
          this.router.navigate(['/life-goals-list']);
          this.lifeGoalsEditForm.reset();
          this.submitted = false;
        }
      });
  }
}
