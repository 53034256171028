import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CustomerService } from "src/app/services/customer.service";
declare var $: any;
import * as moment from "moment";
import { StorageService } from "src/app/services/storage.service";
@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.css"],
})
export class CustomerListComponent implements OnInit {
  constructor(
    public router: Router,
    private customerService: CustomerService,
    private storageService: StorageService,
    private toastr: ToastrService
  ) {
    this.router.getCurrentNavigation()?.extras;
  }

  moment(arg0: any) {
    throw new Error("Method not implemented.");
  }

  token: any;
  userList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  status: any;
  id: any = "";
  search: any = "";
  fromdate: any = "";
  todate: any = "";
  userId: any = [];
  notexist: any;
  submitted: any;
  err: any;
  exportList: any;
  isUserStatus: string = this.storageService.getItem("isUserStatus")
    ? this.storageService.getItem("isUserStatus")
    : "approved";
  registerTime: any = [];
  accessVal: any = false;
  limit: any = 10;
  offset: any;
  userType: String = "Approved";
  blob: any;

  ngOnInit(): void {
    const storageOffset = this.storageService.getItem("offset");
    this.p = storageOffset ? storageOffset + 1 : 1;
    this.userListing();
  }

  unapprovedUserList: any;
  userListing(pageButton?: boolean) {
    this.offset = this.p - 1;
    // if (this.search != "") {
    //   this.offset = 0;
    // }
    console.log(pageButton);
    if (pageButton) {
      this.storageService.setItem("offset", this.offset);
    }
    console.log(this.p - 1);
    console.log(this.offset);
    let obj = {
      limit: this.total,
      offset: this.offset,
      fromdate: this.fromdate,
      todate: this.todate,
      search: this.search,
    };
    this.token = localStorage["token"];
    if (this.isUserStatus == "approved") {
      this.unapprovedUserList = [];
      this.customerService
        .customerList(obj, this.token)
        .subscribe((res: any) => {
          if (res && res.status == 200) {
            this.count = res.data.total_count;
            this.userList = res.data.data;
            for (let i = 0; i < this.userList.length; i++) {
              let element = this.userList[i].registrationDate;
              console.log(element, "ELEMENT");
              this.userList[i].registrationDate = moment(element)
                .tz("Asia/Dubai")
                .format("YYYY-MM-DD HH:mm:ss");
            }
            console.log(this.count, "Count");
          } else if (
            res.status == 400 &&
            res.message == "Please provide valid token"
          ) {
            this.toastr.error(res.message);
            this.router.navigate(["/login"]);
            this.notexist = res.message;
            this.submitted = true;
            this.err = res.data;
          } else if (
            res.status == 400 &&
            res.message ==
            "You can't block this account because it has scheduled meet for future date."
          ) {
            alert(res.message);
          } else {
            this.toastr.success(res.message);
            alert(res.message);
          }
        });
    } else {
      this.userList = [];
      this.customerService
        .customerPendingList(obj, this.token)
        .subscribe((res: any) => {
          if (res && res.status == 200) {
            this.count = res.data.total_count;
            this.unapprovedUserList = res.data.data;
            for (let i = 0; i < this.unapprovedUserList.length; i++) {
              let element = this.unapprovedUserList[i].registrationDate;
              console.log(element, "ELEMENT");
              this.unapprovedUserList[i].registrationDate = moment(element)
                .tz("Asia/Dubai")
                .format("YYYY-MM-DD HH:mm:ss");
            }
            console.log(this.count, "Count");
          } else if (
            res.status == 400 &&
            res.message == "Please provide valid token"
          ) {
            this.toastr.error(res.message);
            this.router.navigate(["/login"]);
            this.notexist = res.message;
            this.submitted = true;
            this.err = res.data;
          } else {
            this.toastr.success(res.message);
            alert(res.message);
          }
        });
    }
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.userListing(true);
  }

  onKeyUpEvent(event: any) {
    this.search = event.target.value;
    this.userListing();
  }

  onExport(name: any) {
    if (confirm("Are you sure you want to export these Customers?")) {
      let obj = {
        search: this.search,
        user_type: name,
      };
      this.token = localStorage["token"];
      this.customerService.exportCustomer(obj).subscribe((res: any) => {
        debugger;
        this.blob = window.URL.createObjectURL(
          new Blob([res], { type: "application/xlsx" })
        );
        console.log(this.blob, "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa");
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.setAttribute("download", "Export Report.xlsx");
        // link.download = "help.pdf";
        link.click();
        // if (res.status == 200) {
        //   this.exportList = res;
        //   window.open(this.exportList, "_self");
        //   this.toastr.success(res.message);
        // } else if (res.status == 201) {
        //   this.toastr.success(res.message);
        // }
      });
    }
  }

  actionKey!: string;
  openModal(id: Number, action: string) {
    this.id = id;
    this.actionKey = action;
    $("#blockUnblockUser").show();
  }

  blockUnblockUser: any;
  blockUnblockDeleteAction() {
    let obj = {
      id: this.id,
      action: this.actionKey,
    };
    this.token = localStorage["token"];
    let x = document.getElementById("closeM");
    x!.setAttribute("data-dismiss", "modal");
    this.customerService.blockUnblock(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        $("#blockUnblockUser").hide();
        this.userListing();
      }
      if (res.status == 400) {
        this.toastr.info(res.message);
        this.userListing();
      }
    });
  }

  openApprovedUnapprovedUserModal(id: any) {
    this.id = id;
    $("#approveUnapproved").show();
  }

  getAccess(event: any) {
    this.accessVal = event.target.checked;
    console.log(this.accessVal, "Access");
  }

  approveUnapproved: any = "approved";
  approveUnapprovedf() {
    let obj = {
      id: this.id,
      lifetime_access: this.accessVal,
    };
    this.token = localStorage["token"];
    let x = document.getElementById("close");
    x!.setAttribute("data-dismiss", "modal");
    this.customerService
      .approvedUnapproved(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $("#approveUnapproved").hide();
          this.userListing();
        }
      });
  }

  isButtonActive(buttonStatus: any) {
    this.storageService.setItem("isUserStatus", buttonStatus);
    this.isUserStatus = buttonStatus;
    this.total = 10;
    this.p = 1;
    this.offset = 0;
    this.userListing();
  }
}
