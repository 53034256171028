import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
declare var $ : any;
@Component({
  selector: 'app-values-list',
  templateUrl: './values-list.component.html',
  styleUrls: ['./values-list.component.css']
})
export class ValueListComponent implements OnInit {
  token: any;
  valueList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  qualification_id: any;
  searchText: any = '';
  sideStatus: any;
  isChecked: any;
  qualificationId: any = [];
  masterSelected: boolean = false;
  exportList: any;

  constructor(    public router: Router,
    private prefrencesService: PreferencesService,
    private toastr : ToastrService,
    private activateroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.valuesListing();
  }

  valuesListing() {
    let offset = this.p - 1;
    if(this.searchText != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.searchText,
    };
    this.token = localStorage['token'];
    this.prefrencesService.valuesList(obj,this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.valueList =  res.data.data;
        this.count = res.data.total_count
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
      }
      else{
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  checkSearchString() {
    if (!this.searchText) {
      // return this.stopSearchLoader();
    }
    this.valuesListing()
   }

   stopSearchLoader() {
    $('.lds-ring').css('display', 'none');
  }

  searchTextFilter() {
    this.valuesListing()
  }


  onTableDataChange(event: any) {
    this.p = event;
    // this.masterSelected = false;
    this.qualificationId = []
    this.valuesListing();
  }


  openModal(item: any){
    this.qualification_id = item.id;
  }

  deletePop(){
    let obj = {
      id: this.qualification_id,
    };
    console.log(obj)
    this.prefrencesService
      .valuesDelete(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 500) {
          this.toastr.success(res.message);
        }
        this.valuesListing();
      });
  }

  // works(event:any){
  //   event.sideName.menuList.forEach((element:any) => {
  //     if(element.role_name=='Master Data')
  //     {
  //       this.sideStatus=element.role_status;
  //     }
  //   });
  // }

}
