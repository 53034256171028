import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RgtService } from 'src/app/services/rgt.service';
import { countries } from "countries-list";
import { City } from 'country-state-city';
import type { TCountryCode } from "countries-list"
declare var $: any;
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {
  editing: boolean = false;
  submitted: any;
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  err: any;
  limit: any = 10;
  offset: any;
  regionId: any = "";
  groupId: any = "";
  tierId: any = "";
  countryList: any;
  regionDetails: any;
  groupList: any;
  groupName: string = "";
  groupCity: any = "";
  groupStatus: any = "";
  tierName: string = "";
  tierRestrictions: any = [];
  tierStatus: any = "";
  dataCityList: Select2Data = [];
  dataTierRestrictionsList: Select2Data = [
    {
      "label": "",
      "options": [
        {
          "value": "Islamic",
          "label": "Islamic",
        },
        {
          "value": "Indian",
          "label": "Indian",
        },
        {
          "value": "Expat",
          "label": "Expat",
        }
      ],
    },
  ]
  dataStatusList: Select2Data = [
    {
      "label": "",
      "options": [
        {
          "value": "ACTIVE",
          "label": "Active",
        },
        {
          "value": "INACTIVE",
          "label": "Inactive",
        }
      ],
    },
  ]


  constructor(
    public router: Router,
    private activateroute: ActivatedRoute,
    private rgtService: RgtService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.regionId = this.activateroute.snapshot.paramMap.get('id');

    this.getRegionDetails()
    this.groupListing()

    let countryCodes = Object.keys(countries);
    this.countryList = (countryCodes.map((code) => {
      return {
        code,
        name: countries[code as TCountryCode].name
      };
    })).sort();
  }

  getRegionDetails() {
    this.token = localStorage['token'];

    this.rgtService.getRegionDetails(this.regionId, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.regionDetails = res.data;

        const country = this.countryList.find((item: any) =>
          item.name == this.regionDetails?.country
        )

        const cityObjs = City.getCitiesOfCountry(country?.code);
        if (cityObjs) {
          let cityList = (cityObjs.map((city: any) => city.name)).sort();

          cityList = cityList.map((city: any) => {
            return {
              value: city,
              label: city
            }
          });

          this.dataCityList = [{
            "label": "",
            "options": cityList
          }]
        }
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  groupListing() {
    this.offset = this.p - 1;
    let obj = {
      region_id: this.regionId,
      limit: this.total,
      offset: this.offset,
    };

    this.token = localStorage['token'];

    this.rgtService.groupList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.groupList = res.data.data;
      } else if (res.status == 400 && res.message == "Please provide valid token") {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    })
  }

  initModal() {
    this.submitted = false;
    this.editing = false;
    this.groupName = "";
    this.groupCity = "";
    this.groupStatus = "ACTIVE";
  }

  openModal(id?: any, editingModal?: boolean) {
    this.initModal();

    if (id) {
      this.groupId = id;
    }

    if (editingModal === true) {
      this.editing = true;

      const group = this.groupList.find((item: any) =>
        item.id == id
      );

      this.groupName = group?.name;
      this.groupCity = group?.city;
      this.groupStatus = group?.status;
    }
  }

  initTierModal() {
    this.submitted = false;
    this.editing = false;
    this.tierName = "";
    this.tierRestrictions = [];
    this.tierStatus = "ACTIVE";
  }

  openTierModal(groupId: any, id?: any, editingModal?: boolean) {
    this.initTierModal()

    this.groupId = groupId;

    if (id) {
      this.tierId = id;
    }

    if (editingModal === true) {
      this.editing = true;

      const group = this.groupList.find((item: any) =>
        item.id == groupId
      );

      if (group) {
        const tier = group.tiers.find((item: any) =>
          item.id == id
        )

        this.tierName = tier?.name;
        this.tierRestrictions = tier?.restrictions ? JSON.parse(tier.restrictions) : [];
        this.tierStatus = tier?.status;
      }
    }
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.groupListing();
  }

  updateGroupCity(event: Select2UpdateEvent) {
    this.groupCity = event.value;
  }

  updateGroupStatus(event: Select2UpdateEvent) {
    this.groupStatus = event.value;
  }

  updateTierRestrictions(event: Select2UpdateEvent) {
    this.tierRestrictions = event.value;
  }

  updateTierStatus(event: Select2UpdateEvent) {
    this.tierStatus = event.value;
  }

  onSubmit() {
    this.submitted = true;
    if (this.groupName == "" || this.groupCity == "") {
      return;
    }

    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('region_id', this.regionId)
    formData.append('id', this.groupId)
    formData.append('name', this.groupName)
    formData.append('city', this.groupCity)
    formData.append('status', this.groupStatus)

    if (this.editing === true) {
      this.rgtService.updateGroup(formData, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message)
          this.groupListing();
          $("#upsertGroupModal").modal("hide");
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.rgtService.createGroup(formData, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message)
          this.groupListing();
          $("#upsertGroupModal").modal("hide");
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  }

  onTierSubmit() {
    this.submitted = true;
    if (this.tierName == "") {
      return;
    }

    this.token = localStorage['token'];
    let formData = new FormData()
    formData.append('group_id', this.groupId)
    formData.append('id', this.tierId)
    formData.append('name', this.tierName)
    formData.append('restrictions', JSON.stringify(this.tierRestrictions))
    formData.append('status', this.tierStatus)

    if (this.editing === true) {
      this.rgtService.updateTier(formData, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message)
          this.groupListing();
          $("#upsertTierModal").modal("hide");
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.rgtService.createTier(formData, this.token).subscribe((res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message)
          this.groupListing();
          $("#upsertTierModal").modal("hide");
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  }
}
