import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { FaqService } from 'src/app/services/faq.service';
declare var $: any;
@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css'],
})
export class FaqListComponent implements OnInit {
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  status: any;
  id: any = '';
  faqList: any;
  search: any;
  chat_id: any;
  faq_id: any;
  notexist: any;
  err: any;
  sideStatus: any;

  constructor(
    public router: Router,
    private faqService: FaqService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.faqListing();
  }

  faqListing() {
    let offset = this.p - 1;
    if(this.search != '')
    {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
    };
    this.token = localStorage['token'];
    this.faqService.faqList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.faqList = res.data;
      }
      else if (res.status == 400 && res.message == "Please provide valid token") {
        // this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.notexist = res.message;
        this.err = res.data
      }
      else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  works(event:any){
    event.sideName.menuList.forEach((element:any) => {
      if(element.role_name=='FAQs')
      {
        this.sideStatus=element.role_status;
      }
    });
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.faqListing();
  }

  openModal(item: any) {
    this.faq_id = item.id;
  }

  deletePop() {
    let obj = {
      faq_id: this.faq_id,
    };
    this.faqService.faqDelete(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        $('#deletModal').modal('hide');
      } else if (res.status == 500) {
        // this.toastr.success(res.message);
      }
      this.faqListing();
    });
  }
}
