import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(public http: HttpClient) { }
  baseUrl = environment.baseUrl

  // Services
  createAdmin(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token,
      })
    }
    return this.http.post(this.baseUrl + `createAdmin`, obj, httpOptions);
  }

  adminList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.get(this.baseUrl + `getAdminList?limit=${obj.limit}&offset=${obj.offset}`, httpOptions);
  }

  sendEmail(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.post(this.baseUrl + `sendEmailWithCredentials`, obj, httpOptions);
  }

  blockUnblockDelete(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "accessToken": token
      })
    }
    return this.http.put(this.baseUrl + `blockUnblockDelete`, obj, httpOptions);
  }
}
